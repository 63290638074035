import React, { FC, Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody, CardHeader } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import AuthContext from '../../../contexts/authContext';
import USERS, { getUserDataWithUsername } from '../../../common/enum/userDummyData';
import Spinner from '../../../components/bootstrap/Spinner';
import Alert from '../../../components/bootstrap/Alert';
import ForgotPassword from './ForgotPassword';
import companyLogo from '../../../assets/img/dd-logo.png';
import axiosInstance from '../../../api/axiosInstance';
import { ENDPOINT } from '../../../api';
import { IAuthInviteParent, IAuthLogin, IAuthLoginUserName, IAuthSignup } from '../../../common/interface/iAuth';
import { fetchRelationshipService, fetchUserBasicInformationService, inviteParentService, loginOtpService, loginService, signUpWithEmailService, validateUserDataService } from '../../../common/service/authService';
import { storageObj } from '../../../common/default/defaultStorage';
import userNameError from '../../../assets/img/userNameError.jpg';
import passwordError from '../../../assets/img/passwordError.jpg';
import * as Yup from 'yup';
import WindmillLoader from '../../../components/WindmillLoader';
import { ERROR_MESSAGE, FORGOT_PASSWORD, FORGOT_USERNAME } from '../../../common/default/enumCustomMessage';
import { ERROR, FAILURE, LOADING, SUCCESS } from '../../../common/default/statusMessage';
import Label from '../../../components/bootstrap/forms/Label';
import { useTranslation } from 'react-i18next';
import { UserProfileType } from '../../../common/enum/enumUserProfileTypes';
import Select from '../../../components/bootstrap/forms/Select';
import Option from '../../../components/bootstrap/Option';
import Icon from '../../../components/icon/Icon';
import { displaySwal, TOAST_TYPE, toastAlert } from '../../../common/functions/displaySwal';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../../components/bootstrap/Modal';
import OTPInput from 'react-otp-input';
import Loading from '../../../components/Loading';
import Swal from 'sweetalert2';
import { emailRegex } from '../../../common/default/regex';

interface ILoginHeaderProps {
	isNewUser?: boolean;
	userName?: string,
	signInPasswordStatus?: boolean,
	inviteParent?: boolean,
	onUpdateRelationList?: any,
}

const inviteParentForm: IAuthInviteParent = {
	first_name: '',
	last_name: '',
	email_id: '',
	user_type_id: 2,
	invite_user: [{
		salutation: '',
		relation_type: '',
		first_name: '',
		last_name: '',
		email_id: '',
	}]
}

interface InviteUserErrors {
	salutation?: string;
	first_name?: string;
	last_name?: string;
	email_id?: string;
	relation_type?: string;
}


let inviteParentFormUserTypeId: any = 3;
sessionStorage.setItem('section', inviteParentFormUserTypeId);

const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser, userName, signInPasswordStatus, inviteParent, onUpdateRelationList }) => {
	const { t } = useTranslation();

	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5 text-blue'>{t('createAccount')}</div>
				<div className='text-center h4 text-muted mb-4 fst-italic'>{t('giveEmailToStarted!')}</div>
			</>
		);
	}
	else if (inviteParent) {

		const elParent = document.getElementsByClassName('parent-heading')[0];
		const elChild = document.getElementsByClassName('child-heading')[0];

		const fetchRelationship = (req: any) => {
			// section = 2 for parent, section = 3 for child 

			// if (req.section == 3) {
			// 	elParent?.classList.add("d-block");
			// 	elParent?.classList.remove("d-none");
			// 	elChild?.classList.add("d-none");
			// 	elChild?.classList.remove("d-block");
			// }
			// else {
			// 	elChild?.classList.add("d-block");
			// 	elChild?.classList.remove("d-none");
			// 	elParent?.classList.add("d-none");
			// 	elParent?.classList.remove("d-block");
			// }
			fetchRelationshipService(req).then((res: any) => {
				if (res?.success === 1) {
					onUpdateRelationList(res?.data);
				}
				else {
					onUpdateRelationList([]);
				}
			})
				.catch((e: any) => {
					onUpdateRelationList([]);
				})
		}
		
		return (
			<>
				<div>
					<div className='d-flex justify-content-center align-items-center family-cursive f-s-14 text-pink mt-4'>
						<label className="form-check-label">{t('SignUpAsParent')}</label>
						<div className="optionParentKid mx-2">
							<div className="toggle">
								<input type="checkbox" name="toggle" className="check-checkbox" id="mytoggle"
									onChange={(e) => {
										inviteParentFormUserTypeId = e.target.checked ? UserProfileType.Child : UserProfileType.Parent;
										sessionStorage.setItem('section', inviteParentFormUserTypeId);
										fetchRelationship({ section: inviteParentFormUserTypeId == UserProfileType.Parent ? UserProfileType.Child : UserProfileType.Parent });
									}}
									checked={sessionStorage.getItem('section') == 3 as any}
								/>
								<label className="check-label" htmlFor="mytoggle">
									<div id="background"></div>
									<span className="face">
										<span className="face-container">
										</span>
									</span>
								</label>
							</div>
						</div>
						<label className="form-check-label kid-label">
							{t('SignUpAsChild')}
						</label>
					</div>
					{(sessionStorage.getItem('section')) == '2' && <div className='text-center fs-6 mt-5 mb-4 text-yellow px-5 fst-italic bg-yellow-light rounded-2 py-3 mx-auto parent-heading'>{t('parentInviteText')}</div>}
				</div>
				{(sessionStorage.getItem('section')) != '2' &&
					<div className='child-heading'>
						<div className='text-center h3 fw-bold mt-5 text-blue'>{t('heckYa')}</div>
						<div className='text-center h4 text-muted mb-4 fst-italic'>{t('pleaseInvite')}</div>
					</div>
				}
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5 text-blue'>{t('welcome')}</div>
			<div className='text-center h4 text-muted mb-4 fst-italic'>{t('signinToContinue!')}</div>
		</>
	);
};

interface ILoginProps {
	isSignUp?: boolean;
}

const Login: FC<ILoginProps> = ({ isSignUp }) => {
	const { setUser } = useContext(AuthContext);

	const { darkModeStatus } = useDarkMode();

	const tab = localStorage.getItem('tab');
	const welcomeUserName = sessionStorage.getItem('user');
	const welcomePassWord = sessionStorage.getItem('password');

	const [signInPassword, setSignInPassword] = useState<boolean>(false);
	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);
	const [inviteParent, setInviteParent] = useState<boolean>(false);
	const [fullUserName, setFullUserName] = useState<string>('');
	const [showAlertForLogin, setShowAlertForLogin] = useState<boolean>(false);
	const [userSuccess, setUserSuccess] = useState<boolean>(false);
	const [alertMessage, setAlertMessage] = useState<string>('');
	const [signupFlag, setSignupFlag] = useState<boolean>(false);
	const [showComponent, setShowComponent] = useState(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [redierctTimer, setRedirectTimer] = useState<number>(10);
	const [buttonType, setButtonType] = useState<number | string>(tab || 0);
	const [inviteParentFormFields, setInviteParentFormFields] = useState({ ...inviteParentForm });

	const [otp, setOtp] = useState('');
	const [ShowModal, setShowModal] = useState(false);
	const [inputDisabled, setInputDisabled] = useState<boolean>(true);


	// signup -----------------------------------------

	const [showAlert, setShowAlert] = useState<boolean>(false);
	const [isMailSendindSuccess, setIsMailSendindSuccess] = useState<boolean>(false);
	const [isProcessing, setIsProcessing] = useState<boolean>(false);
	const [mailSendingStatus, setMailSendingStatus] = useState<string>('');
	const [otpSendingStatus, setOtpSendingStatus] = useState<string>('');
	const [relationshipData, setRelationshipData] = useState<[]>([]);
	const [userTypeId, setUserTypeId] = useState<any>();


	let timer = 10;

	// VisibilityOff/Visibile password field
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/dashboard'), [navigate]);

	//Login Error Handle..
	const [showLoginError, setShowLoginError] = useState(false);

	const successTitle = t('GenericMessage.success');
	const errorTitle = t('GenericMessage.sorry');
	const errorText = t('somethingWentWrong');
	const confirmButtonText = t('GenericMessage.ok');

	useEffect(() => {
		getRelationhips({ section: 2 })
		if (!!welcomeUserName && !!welcomePassWord) {
			sendConfirmationMail();
		}
	}, [welcomeUserName, welcomePassWord])

	useEffect(() => {
		setShowAlertForLogin(false);
		const inputElement = document.getElementById('loginUsername') as HTMLInputElement;
		if (inputElement) {
			inputElement.focus();
		}
	}, [signInPassword])

	useEffect(()=>{
		// setRedirectTimer(10)	
		const ref = countdown()
		
		return ()=>{
			clearTimeout(ref)
			setRedirectTimer(10)	
		}
	},[showLoginError])

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: '',
			loginPassword: '',
		},
		validationSchema: Yup.object({
			loginUsername: Yup.string()
				.required(t('usernameRequired') || '')
				.test(
					'is-not-email',
					t('pleaseEnterUsernameNotEmail') || '',
					value => !emailRegex.test(value)
				),
			loginPassword: Yup.string()
				.required(t('passwordRequired') || '')
			// .min(8, 'Password should be minimum of 8 characters')
			// .matches(/[A-Z]/, "Atleast use one UpperCase")
			// .matches(/[a-z]/, "Atleast use one  LowerCase")
			// .matches(/[0-9]/, "Atleast use one  Number")
			// .matches(/[^\w]/, "Atleast use one  Special Character"),
		}),
		validateOnChange: false,
		onSubmit: (values) => {
			sendConfirmationMail();
		},
	});

	const handleLoginKeyPress = (e: any) => {
		if (e.key === 'Enter') {
			formik.handleSubmit()
		}
	};

	const handleLogin = (req: IAuthLogin) => {
		setIsLoading(true)
		loginService(req).then((res: any) => {
			if (res.success == 1) {
				setUserSuccess(true);
				setAlertMessage('');
				setShowAlertForLogin(false);
				setShowModal(false);
				Swal.fire({
					title: t('verifiedSuccess') || '',
					text: t('redirectLogin') || '',
					timer: 3000,
					timerProgressBar: true,
					icon: 'success'
				}).then((result) => {
					handleOnClick();
					if (setUser) {
						setUser(req.username);
					}
					storageObj.setToken(res.token);
					sessionStorage.removeItem('password');
				});
			} else {
				displaySwal(errorTitle, res.message, res?.success, confirmButtonText);
			}
		})
			.catch(err => {
				displaySwal(errorTitle, err.message, 0, confirmButtonText);
			})
			.finally(() => {
				setIsLoading(false);
				setOtp('');
				// setShowModal(false);
			})
	}

	const countdown = () => {
		let timerId = setInterval(() => {
			if (timer > 0) {
				timer = timer - 1;
				setRedirectTimer(timer);
			} else {
				clearInterval(timerId);
				setRedirectTimer(10);
				timer = 10;
				handleLoginErrorFalse();
				return;
			}

		}, 1000)
		return timerId;
		
	}

	// const handleContinue = () => {
	// 	const user = formik.values.loginUsername;
	// 	setShowAlertForLogin(false);
	// 	if (user === "") {
	// 		formik.setFieldError('loginUsername', 'Please Enter Username.')
	// 	} else {
	// 		const req = { username: user }
	// 		callValidateUserData(req)
	// 	}
	// };

	const handleShow = () => {
		setShowComponent(true);
	}

	const changeShowComponentToFalse = useCallback(() => {
		setShowComponent(false)
	}, [])

	const handleLoginError = () => {
		setShowLoginError(true);
	}
	const handleLoginErrorFalse = () => {
		setShowLoginError(false);
	}

	// signup -----------------------------------------

	const formikObj = useFormik({
		enableReinitialize: true,
		initialValues: {
			signUpEmail: '',
			user_type_id: 2,
		},
		validate: (values) => {
			const errors: { signUpEmail?: string, user_type_id?: string } = {};
			// if (!values.signUpEmail) {
			// 	errors.signUpEmail = t('notValidEmail')||'';
			// }
			if (!values.signUpEmail || !values.signUpEmail.includes('@')) {
				errors.signUpEmail = t('notValidEmail') || '';
				return errors;
			}
			// if (!values.user_type_id) {
			// 	errors.user_type_id = '*Select User Type*';
			// }
			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => {
			const req = { email: values.signUpEmail, user_type_id: values.user_type_id }
			setShowAlert(true)
			setMailSendingStatus(LOADING);
			setIsProcessing(true)
			sendRegistrationReqMail(req);
			setSignupFlag(true);
		}
	})

	const formikInviteParent = useFormik({
		enableReinitialize: true,
		initialValues: inviteParentFormFields,
		validationSchema: Yup.object().shape({
			first_name: Yup.string().required(t('firstNameRequired') || ''),
			last_name: Yup.string().required(t('lastNameRequired') || ''),
			email_id: Yup.string().email(t('invalidEmailAddress') || '').required(t('emailRequired') || ''),
			invite_user: Yup.array().of(
				Yup.object().shape({
					salutation: Yup.string().required(t('salutationRequired') || ''),
					first_name: Yup.string().required(t('firstNameRequired') || ''),
					last_name: Yup.string().required(t('lastNameRequired') || ''),
					email_id: Yup.string().email(t('invalidEmailAddress') || '').required(t('emailRequired') || ''),
					relation_type: Yup.string().required(t('relationshipRequired') || ''),
				})
			),
		}),
		onSubmit: (values) => {
			setIsLoading(true);
			values.user_type_id = inviteParentFormUserTypeId;
			const filteredValue = values?.invite_user?.map((i: any) => {
				return { ...i, email_id: i?.email_id?.toLowerCase() }
			})
			const sendData = { ...values, email_id: values?.email_id?.toLowerCase(), invite_user: filteredValue }
			inviteParentService(sendData).then((res: any) => {
				if (res?.success === 1) {
					displaySwal(successTitle, res?.message, res?.success, confirmButtonText);
					setButtonType(0);
					setInviteParent(false);
				}
				else {
					displaySwal(errorTitle, res?.message, res?.success, confirmButtonText);
				}
			})
				.catch(() => {
					displaySwal(errorTitle, errorText, 0, confirmButtonText);
				})
				.finally(() => {
					setIsLoading(false);
					formikInviteParent.resetForm();
					formikInviteParent.handleReset;
				})
		}
	})

	const handleSignUpKeyPress = (e: any) => {
		if (e.key === 'Enter') {
			formikObj.handleSubmit()
		}
	};


	const sendRegistrationReqMail = (req: IAuthSignup) => {
		signUpWithEmailService({ ...req, email: req?.email?.toLowerCase() }).then((res: any) => {
			if (res.success == 1) {
				// setShowAlert(true)
				setIsProcessing(false)
				setIsMailSendindSuccess(true);
				setMailSendingStatus(SUCCESS);
				// setAlertMessage(SUCCESS_MAIL_SEND_MESSAGE);
				storageObj.setNewUser(true);

			} else {
				// setShowAlert(true)
				setIsProcessing(false)
				setIsMailSendindSuccess(false)
				setMailSendingStatus(FAILURE);
				// setAlertMessage(FAILURE_MAIL_SEND_MESSAGE)
			}
		}).catch(error => {
			// setShowAlert(true)
			setIsProcessing(false)
			setIsMailSendindSuccess(false)
			// setAlertMessage(ERROR_MESSAGE)
			setMailSendingStatus(ERROR);
		})
	}

	const getRelationhips = (req: any) => {
		// section = 2 for parent, section = 3 for child 
		fetchRelationshipService(req).then((res: any) => {
			if (res?.success === 1) {
				setRelationshipData(res?.data);
			}
			else {
				setRelationshipData([]);
			}
		})
			.catch((e: any) => {
				setRelationshipData([]);
			})
	}
	const HandleOTPInputs = (value: any) => {
		setOtp(value);
		setInputDisabled(value.length < 6);
	}

	const handleUserLoginAuthenticate = (userType: any) => {
		const req = {
			username: !!welcomeUserName ? welcomeUserName : formik.values.loginUsername,
			password: !!welcomePassWord ? welcomePassWord : formik.values.loginPassword,
			otp: otp ? otp : '',
			user_type_id: userType
		}
		handleLogin(req);
	}

	const sendConfirmationMail = () => {
		setIsProcessing(true);
		const req = {
			username: !!welcomeUserName ? welcomeUserName : formik.values.loginUsername,
			password: !!welcomePassWord ? welcomePassWord : formik.values.loginPassword,
		}
		loginOtpService(req).then((res: any) => {
			if (res.success == 1) {
				// res.is_active = 0 --> household deactive ; res.is_active = 1 --> household active
				if (res.is_active == 1) {
					setUserTypeId(res?.user_type_id)
					if (res?.user_type_id == UserProfileType.Child) {
						setOtp('')
						handleUserLoginAuthenticate(res?.user_type_id)
					} else {
						setOtp('')
						setShowModal(true);
						setOtpSendingStatus(res?.message)
					}
				}
				else {
					toastAlert(TOAST_TYPE.ERROR, t('householdDeactiveContactAdmin'))
				}
			} else {
				handleLoginError();
				setUserSuccess(false)
				setAlertMessage(!!res.message ? res.message : '');
				setShowAlertForLogin(true);
				// countdown();
			}
		}).catch(error => {
			handleLoginError();  // LOGIN Error
			setUserSuccess(false);
			setAlertMessage(t('somethingWentWrong') || '');
			setShowAlertForLogin(true);
			// countdown();
		}).finally(() => {
			setIsProcessing(false);
		})
	}

	return (
		<>
			{signupFlag === false ?
				<React.Fragment>

					{showComponent ? <ForgotPassword changeShowComponent={changeShowComponentToFalse} forgotType={!signInPassword ? FORGOT_USERNAME : FORGOT_PASSWORD} />
						:
						<>
							<PageWrapper
								isProtected={false}
								title={singUpStatus ? 'Sign Up' : 'Login'}
								className={classNames({ 'bg-warning': !singUpStatus, 'bg-info': singUpStatus })}>
								<Page className='p-0'>
									<div className='row h-100 align-items-center justify-content-center'>
										<div className='col-xl-6 col-lg-12 col-md-10 shadow-3d-container'>
											<Card className='shadow-3d-dark' data-tour='login-page'>
												<CardBody>
													<div className='text-center my-3'>
														<Link
															to='/'
															className={classNames(
																'text-decoration-none  fw-bold display-2',
																{
																	'text-dark': !darkModeStatus,
																	'text-light': darkModeStatus,
																},
															)}>


															<img alt='logo' style={{ width: 200 }} src={String(companyLogo)} />
														</Link>
													</div>

													{/* Start Login Error Handle */}

													{showLoginError ? (
														<>
															{!signInPassword ?
																(<div className='text-center my-3'>
																	<img src={userNameError} alt='userNameError' style={{ width: 200 }} />
																	<h1>{t('oops!')}</h1>
																	<p>{t('incorrectUsernameTryAgainOrCreateAccount')}</p>
																	<Button className='bg-primary text-light' onClick={handleLoginErrorFalse}>{t('GenericMessage.ok')} ({redierctTimer})</Button>
																</div>
																) : (
																	<div className='text-center my-3'>
																		<img src={passwordError} alt='passwordError' style={{ width: 200 }} />
																		<h1>{t('oops!')}</h1>
																		<p>{t('incorrectPasswordTryAgain')}</p>
																		<Button className='bg-primary text-light' onClick={handleLoginErrorFalse}>{t('GenericMessage.ok')} ({redierctTimer})</Button>
																	</div>)
															}
														</>
													) : (
														<>
															<div
																className={classNames('rounded-3', {
																	'bg-l10-dark': !darkModeStatus,
																	'bg-dark': darkModeStatus,
																})}>
																<div className='row row-cols-3 g-3 pb-3 px-3 mt-0'>
																	<div className='col'>
																		<Button
																			color={buttonType == 0 ? 'dark' : 'light'}
																			isLight={buttonType != 0}
																			className='rounded-1 w-100 btn-login'
																			size='lg'
																			onClick={() => {
																				setSignInPassword(false);
																				setSingUpStatus(false);
																				setInviteParent(false);
																				setButtonType(0);
																			}}>
																			{t('login')}
																		</Button>
																	</div>
																	<div className='col'>
																		<Button
																			color={buttonType == 1 ? 'dark' : 'light'}
																			isLight={buttonType != 1}
																			className='rounded-1 w-100'
																			size='lg'
																			onClick={() => {
																				setSignInPassword(false);
																				setSingUpStatus(true);
																				setInviteParent(false);
																				setButtonType(1);
																			}}>
																			{t('signUp')}
																		</Button>
																	</div>
																	<div className='col'>
																		<Button
																			color={buttonType == 2 ? 'dark' : 'light'}
																			isLight={buttonType != 2}
																			className='rounded-1 w-100'
																			size='lg'
																			onClick={() => {
																				setSignInPassword(false);
																				setSingUpStatus(false);
																				setInviteParent(true);
																				setButtonType(2);
																				getRelationhips({ section: 2 })
																			}}>
																			{t('inviteMyParent')}
																		</Button>
																	</div>
																</div>
															</div>

															<LoginHeader isNewUser={singUpStatus} userName={fullUserName} signInPasswordStatus={signInPassword} inviteParent={inviteParent} onUpdateRelationList={(val: any) => setRelationshipData(val)} />

															{(!!welcomeUserName && !!welcomePassWord) ?
																<Fragment>
																	<CardBody className='d-flex justify-content-center mb-5'>
																		<Spinner color="primary" isGrow className='me-2'></Spinner><h3 className='text-blue'>{t('weLoggingYou')}</h3>
																	</CardBody>
																</Fragment>
																:
																<form className='row g-4'>

																	{buttonType == 0 && (
																		<>
																			<div className='col-12'>

																				<FormGroup
																					id='loginUsername'
																					isFloating
																					label={t('username') || ''}
																				>
																					<Input
																						autoComplete='new-password'
																						required
																						value={formik.values.loginUsername}
																						// isTouched={formik.touched.loginUsername}
																						// invalidFeedback={
																						// 	formik.errors.loginUsername
																						// }
																						// isValid={formik.isValid}
																						onChange={formik.handleChange}
																						// onBlur={formik.handleBlur}
																						// onFocus={() => {
																						// 	formik.setErrors({});
																						// }}
																						onKeyDown={handleLoginKeyPress}
																					/>
																				</FormGroup>
																				<div className='text-danger f-s-10'>
																					{formik.errors.loginUsername}
																				</div>
																				<br></br>
																				<div className='position-relative'>
																					<FormGroup
																						id='loginPassword'
																						isFloating
																						label={t('password') || ''}
																						className='position-relative'>
																						<Input
																							type={showPassword ? 'text' : 'password'}
																							autoComplete='new-password'
																							value={formik.values.loginPassword}
																							// isTouched={formik.touched.loginPassword}
																							// invalidFeedback={
																							// 	formik.errors.loginPassword
																							// }
																							// validFeedback='Looks good!'
																							// isValid={formik.isValid}
																							onChange={formik.handleChange}
																							// onBlur={formik.handleBlur}
																							onKeyDown={handleLoginKeyPress}
																						/>
																					</FormGroup>

																					<FormGroup
																						style={{
																							top: '9px',
																							right: '5px',
																						}}
																						className='position-absolute'>
																						<Button
																							onClick={() => setShowPassword(!showPassword)}
																							icon={!showPassword ? 'VisibilityOff' : 'Visibility'}>
																						</Button>
																					</FormGroup>
																				</div>
																				<div className='text-danger f-s-10'>
																					{formik.errors.loginPassword}
																				</div>
																			</div>

																			<Link
																				to=''
																				className={classNames(
																					'text-decoration-none text-end  fw-bold display-2',
																					{
																						'text-dark': !darkModeStatus,
																						'text-light': darkModeStatus,
																					},
																				)}
																			// onClick={handleShow}
																			>
																				{/* <h6>{!signInPassword ? 'Forgot Username?' : 'Forgot Password?'}</h6> */}
																				<h6>{t('forgot')} <u onClick={() => { setSignInPassword(false); handleShow() }}>{t('Username')}</u> / <u onClick={() => { setSignInPassword(true); handleShow() }}>{t('Password')}</u></h6>
																			</Link>

																			<div className='col-12 d-flex justify-content-center'>
																				<Button
																					color='warning'
																					className='w-50 py-3'
																					isDisable={isProcessing}
																					onClick={formik.handleSubmit}>
																					{isProcessing && (
																						<Spinner isSmall inButton isGrow />
																					)}
																					{t('login')}
																				</Button>
																			</div>
																		</>
																	)}

																	{buttonType == 1 &&
																		(
																			<>

																				<React.Fragment>
																					<React.Fragment>
																						<div className='d-flex justify-content-center align-items-center family-cursive f-s-14 text-pink'>
																							<label className="form-check-label">{t('SignUpAsParent')}</label>
																							<div className="optionParentKid mx-2">
																								<div className="toggle">
																									<input type="checkbox" name="toggle" className="check-checkbox" id="mytoggle"
																										onChange={(e) => {
																											formikObj.setFieldValue('user_type_id', e.target.checked ? UserProfileType.Child : UserProfileType.Parent);
																										}}
																									/>
																									<label className="check-label" htmlFor="mytoggle">
																										<div id="background"></div>
																										<span className="face">
																											<span className="face-container">
																											</span>
																										</span>
																									</label>
																								</div>
																							</div>
																							<label className="form-check-label kid-label">
																								{t('SignUpAsChild')}
																							</label>
																						</div>



																						{formikObj.errors.user_type_id && <span className='text-danger'>{formikObj.errors.user_type_id}</span>}
																						<div className='col-12'>
																							{/* <Label className='ms-1'>{t('email') || ''} <span className='text-danger'>*</span></Label> */}
																							<FormGroup
																								id='signUpEmail'
																								isFloating
																								label={t('email') || ''}
																							>
																								<Input type='email'
																									autoComplete='new-password'
																									value={formikObj.values.signUpEmail}
																									isTouched={formikObj.touched.signUpEmail}
																									invalidFeedback={
																										formikObj.errors.signUpEmail
																									}
																									isValid={formikObj.isValid}
																									onChange={formikObj.handleChange}
																									onBlur={formikObj.handleBlur}
																									onFocus={() => {
																										formikObj.setErrors({});
																									}}
																									onKeyDown={handleSignUpKeyPress}
																								/>
																							</FormGroup>
																						</div>

																						<div className='col-12 d-flex justify-content-center'>
																							<Button
																								color='info'
																								className='w-50 py-3'
																								onClick={formikObj.handleSubmit}>
																								{t('register')}
																							</Button>
																						</div>
																					</React.Fragment>

																				</React.Fragment>
																			</>
																		)
																	}

																	{(buttonType == 2) &&
																		(
																			<>
																				{(sessionStorage.getItem('section')) != '2' &&
																					<React.Fragment>
																						<FormikProvider value={formikInviteParent}>
																							<Form onSubmit={formikInviteParent.handleSubmit}>
																								<Card className='border'>
																									<CardHeader className='pb-0'>
																										<h5 className='text-primary mb-0'>{(t('myDetails'))}</h5>
																									</CardHeader>
																									<hr className='divider-gray-light' />
																									<CardBody className='p-2 mb-2 ms-3'>
																										<div className='row'>
																											<div className='col-md-6'>
																												<Label>{t('firstName') || ''}</Label>
																												<FormGroup id='first_name'>
																													<Input
																														type='text'
																														autoComplete='new-password'
																														name='first_name'
																														value={formikInviteParent.values.first_name}
																														invalidFeedback={
																															formikInviteParent.errors.first_name
																														}
																														isValid={formikInviteParent.isValid}
																														onChange={formikInviteParent.handleChange}
																														onBlur={formikInviteParent.handleBlur}
																														onFocus={() => {
																															formikInviteParent.setErrors({});
																														}}
																													/>
																												</FormGroup>
																												{ formikInviteParent.errors.first_name != undefined && <div className='text-danger f-s-10'>
																													{formikInviteParent.errors.first_name}
																												</div>}
																											</div>
																											<div className='col-md-6'>
																												<Label>{t('lastName') || ''} </Label>
																												<FormGroup id='last_name'>
																													<Input
																														type='text'
																														autoComplete='new-password'
																														value={formikInviteParent.values.last_name}
																														invalidFeedback={
																															formikInviteParent.errors.last_name
																														}
																														isValid={formikInviteParent.isValid}
																														onChange={formikInviteParent.handleChange}
																														onBlur={formikInviteParent.handleBlur}
																														onFocus={() => {
																															formikInviteParent.setErrors({});
																														}}
																													/>
																												</FormGroup>
																												{ formikInviteParent.errors.last_name != undefined && <div className='text-danger f-s-10'>
																													{formikInviteParent.errors.last_name}
																												</div>}
																											</div>
																										</div>
																										<div className='row mt-1'>
																											<div className='col-md-12'>
																												<Label>{t('email') || ''} </Label>
																												<FormGroup id='email_id'>
																													<Input type='email'
																														autoComplete='new-password'
																														value={formikInviteParent.values.email_id}
																														isTouched={formikInviteParent.touched.email_id}
																														// invalidFeedback={
																														// 	// formikInviteParent.errors.email_id
																														// }
																														isValid={formikInviteParent.isValid}
																														onChange={formikInviteParent.handleChange}
																														onBlur={formikInviteParent.handleBlur}
																														onFocus={() => {
																															formikInviteParent.setErrors({});
																														}}
																														onKeyDown={handleSignUpKeyPress}
																													/>
																												</FormGroup>
																												{ formikInviteParent.errors.email_id != undefined && <div className='text-danger f-s-10'>
																													{formikInviteParent.errors.email_id}
																												</div>}
																											</div>
																										</div>
																									</CardBody>
																								</Card>

																								{ relationshipData?.length == 0 ? <Loading/> : <FieldArray
																									name='invite_user'
																									render={arrayhelpers => (
																										<>
																											{formikInviteParent.values.invite_user.map((field, index) => {
																												const isLastRow = formikInviteParent.values?.invite_user?.length - 1 === index;
																												const getFieldName = (name: any) => `${arrayhelpers.name}[${index}][${name}]`;
																												return (
																													<div key={index}>
																														<Card className='border'>
																															<CardHeader className='pb-0'>
																																<h5 className='text-primary mb-0'>{t('Invitee') + ' ' + (index + 1)}</h5>
																															</CardHeader>
																															<hr className='divider-gray-light' />
																															<CardBody className='p-2 mb-2 ms-3'>
																																<div className='row'>
																																	<div className='col-md-2'>
																																		<Label>{t('Salutation') || ''} </Label>
																																		<FormGroup >
																																			<Select
																																				ariaLabel='Salutation'
																																				id='salutation'
																																				size='sm'
																																				name={getFieldName('salutation')}
																																				value={field.salutation}
																																				onChange={formikInviteParent.handleChange}>
																																				<Option>{(t('Select'))}</Option>
																																				<Option value='Mr.'>{(t('Mr'))}</Option>
																																				<Option value='Mrs.'>{(t('Mrs'))}</Option>
																																				<Option value='Ms.'>{(t('Ms.'))}</Option>
																																			</Select>

																																		</FormGroup>
																																		{formikInviteParent.errors.invite_user!=undefined &&<div className='text-danger f-s-10'>
																																			{/* {formikInviteParent?.errors?.invite_user[index]?.salutation || ''} */}
																																			{(formikInviteParent.errors.invite_user as InviteUserErrors[])[index]?.salutation || ''}
																																		</div>}

																																	</div>
																																	<div className='col-md-5'>
																																		<Label>{t('firstName') || ''}</Label>
																																		<FormGroup id='first_name'>
																																			<Input
																																				type='text'
																																				autoComplete='new-password'
																																				name={getFieldName('first_name')}
																																				value={field.first_name}
																																				isValid={formikInviteParent.isValid}
																																				onChange={formikInviteParent.handleChange}
																																				onBlur={formikInviteParent.handleBlur}
																																				onFocus={() => {
																																					formikInviteParent.setErrors({});
																																				}}
																																			/>
																																		</FormGroup>
																																		{ formikInviteParent.errors.invite_user!=undefined && <div className='text-danger f-s-10'>
																																			{(formikInviteParent.errors.invite_user as InviteUserErrors[])[index]?.first_name || ''}
																																		</div>}
																																	</div>
																																	<div className='col-md-5'>
																																		<Label>{t('lastName') || ''} </Label>
																																		<FormGroup id='last_name'>
																																			<Input
																																				type='text'
																																				required
																																				autoComplete='new-password'
																																				name={getFieldName('last_name')}
																																				value={field.last_name}
																																				isValid={formikInviteParent.isValid}
																																				onChange={formikInviteParent.handleChange}
																																				onBlur={formikInviteParent.handleBlur}
																																				onFocus={() => {
																																					formikInviteParent.setErrors({});
																																				}}
																																			/>
																																		</FormGroup>
																																		{formikInviteParent.errors.invite_user!=undefined && <div className='text-danger f-s-10'>
																																			{(formikInviteParent.errors.invite_user as InviteUserErrors[])[index]?.last_name || ''}
																																		</div>}
																																	</div>
																																</div>
																																<div className='row mt-1'>
																																	<div className='col-md-8'>
																																		<Label>{t('email') || ''} </Label>
																																		<FormGroup id='email_id'>
																																			<Input type='email'
																																				autoComplete='new-password'
																																				name={getFieldName('email_id')}
																																				value={field.email_id}
																																				isValid={formikInviteParent.isValid}
																																				onChange={formikInviteParent.handleChange}
																																				onBlur={formikInviteParent.handleBlur}
																																				onFocus={() => {
																																					formikInviteParent.setErrors({});
																																				}}
																																				onKeyDown={handleSignUpKeyPress}
																																			/>
																																		</FormGroup>
																																		{formikInviteParent.errors.invite_user!=undefined && <div className='text-danger f-s-10'>
																																			{(formikInviteParent.errors.invite_user as InviteUserErrors[])[index]?.email_id || ''}
																																		</div>}
																																	</div>

																																	<div className='col-md-4'>
																																		<Label>{t('relationship') || ''} </Label>
																																		<FormGroup >
																																			<Select
																																				ariaLabel='relation_type'
																																				id='relation_type'
																																				size='sm'
																																				name={getFieldName('relation_type')}
																																				value={field.relation_type}
																																				onChange={formikInviteParent.handleChange}>
																																				<Option>{(t('Select'))}</Option>
																																				{relationshipData?.map((r: any, i: number) => (
																																					<Option value={r.name} key={i}>{r.name}</Option>
																																				))
																																				}
																																			</Select>
																																		</FormGroup>
																																		{formikInviteParent.errors.invite_user!=undefined && <div className='text-danger f-s-10'>
																																			{(formikInviteParent.errors.invite_user as InviteUserErrors[])[index]?.relation_type || ''}
																																		</div>}
																																	</div>
																																</div>
																															</CardBody>
																														</Card>
																														<div className='cursor-pointer d-flex justify-content-end mb-3'>
																															{formikInviteParent.values?.invite_user?.length > 1 &&
																																<Icon
																																	icon={'RemoveCircle'}
																																	color={'danger'}
																																	size={'2x'}
																																	forceFamily={'material'}
																																	onClick={() => arrayhelpers.remove(index)}
																																/>
																															}
																															{(isLastRow && index < 1) &&
																																<Icon
																																	icon={'AddCircle'}
																																	color={'primary'}
																																	size={'2x'}
																																	forceFamily={'material'}
																																	onClick={() => arrayhelpers.push({
																																		salutation: '',
																																		relation_type: '',
																																		first_name: '',
																																		last_name: '',
																																		email_id: '',
																																	})}
																																/>
																															}
																														</div>
																													</div>
																												)
																											})}
																										</>
																									)}
																								/>}

																								<div className='col-12 d-flex justify-content-center'>
																									<Button
																										type='submit'
																										color='info'
																										className='w-50 py-3'
																										onClick={formikInviteParent.handleSubmit}>
																										{isLoading && (
																											<Spinner isSmall inButton isGrow />
																										)}
																										{t('invite')}
																									</Button>
																								</div>
																							</Form>
																						</FormikProvider>
																						{/* <div className='col-12 d-flex justify-content-center'>
																							<Button
																								color='info'
																								className='w-50 py-3'
																								onClick={formikInviteParent.handleSubmit}>
																								{isLoading && (
																									<Spinner isSmall inButton isGrow />
																								)}
																								{t('invite')}
																							</Button>
																						</div> */}
																					</React.Fragment>
																				}
																			</>
																		)
																	}

																	{/* BEGIN :: Social Login */}
																	{/* {!signInPassword && (
																	<>

																		<div className='col-12 mt-3 text-center text-muted'>
																			OR
																		</div>
																		<div className='col-12 mt-3'>
																			<Button
																				isOutline
																				color={darkModeStatus ? 'light' : 'dark'}
																				className={classNames('w-100 py-3', {
																					'border-light': !darkModeStatus,
																					'border-dark': darkModeStatus,
																				})}
																				icon='CustomApple'
																				onClick={handleOnClick}>
																				Sign in with Apple
																			</Button>
																		</div>
																		<div className='col-12'>
																			<Button
																				isOutline
																				color={darkModeStatus ? 'light' : 'dark'}
																				className={classNames('w-100 py-3', {
																					'border-light': !darkModeStatus,
																					'border-dark': darkModeStatus,
																				})}
																				icon='CustomGoogle'
																				onClick={handleOnClick}>
																				Continue with Google
																			</Button>
																		</div>
																	</>
																)} */}
																	{/* END :: Social Login */}
																</form>
															}
														</>
													)}
													{/* End Login Error Handle */}
												</CardBody>
											</Card>
											<div className='text-center'>
												<a
													href='/'
													className={classNames('text-decoration-none me-3', {
														'link-light': singUpStatus,
														'link-dark': !singUpStatus,
													})}>
													{t('privacypolicy')}
												</a>
												<a
													href='/'
													className={classNames('link-light text-decoration-none', {
														'link-light': singUpStatus,
														'link-dark': !singUpStatus,
													})}>
													{t('termsofuse')}
												</a>
											</div>
										</div>
									</div>
								</Page>
							</PageWrapper>
							<Modal
								id={'r'}
								titleId={'a'}
								isOpen={ShowModal}
								setIsOpen={setShowModal}
								isStaticBackdrop={true}
								isScrollable={true}
								isCentered={true}

								// size='lg'
								isAnimation={false}>
								<ModalHeader setIsOpen={setShowModal}>
									<ModalTitle id='title'>
										<h5 className="mb-0 text-dark text-center"> </h5>
									</ModalTitle>
								</ModalHeader>
								{isProcessing ?
									<ModalBody>
										<Loading />
									</ModalBody>
									:
									<>
										<ModalBody>
											<div className='text-center mb-2 ms-4 me-4'>
												<Link
													to='/'
													className={classNames(
														'text-decoration-none  fw-bold display-2',
														{
															'text-dark': !darkModeStatus,
															'text-light': darkModeStatus,
														},
													)}>
													<img alt='logo' style={{ width: 150 }} src={String(companyLogo)} />
												</Link>
												<p className=''>{otpSendingStatus}</p>
											</div>
											<OTPInput
												value={otp}
												onChange={HandleOTPInputs}
												numInputs={6}
												renderSeparator={<span>-</span>}
												renderInput={(props) => <input {...props} />}
												inputStyle={{
													width: '2em',
													textAlign: 'center',
													height: '2em',
													fontSize: '20px',
													fontWeight: 700
												}}
												containerStyle={{
													justifyContent: "center"
												}}
											/>

										</ModalBody>
										<ModalFooter>
											<div className='col-12 mt-2 d-flex justify-content-center'>
												<Button
													color='info'
													className='w-25 py-3'
													onClick={() => handleUserLoginAuthenticate(userTypeId)}

													isDisable={isLoading || inputDisabled}
												>
													{isLoading && (
														<Spinner isSmall inButton isGrow />
													)}
													{t('verifyOTP')}
												</Button>
											</div>
											<div className='col-12 d-flex justify-content-end'>
												<p>{t('didNotGetCode')}{' '}
													<i className='text-primary fw-bold cursor-pointer' onClick={sendConfirmationMail} hidden={isProcessing} >{t('resend')}</i>
												</p>
											</div>
										</ModalFooter>
									</>
								}

							</Modal>
						</>
					}
				</React.Fragment>
				:
				<WindmillLoader
					mailSendingStatus={mailSendingStatus}
					btnClick={() => {
						setSingUpStatus(false);
						setSignupFlag(false);
						setButtonType(0);
					}}
				/>
			}

		</>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
