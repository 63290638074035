import React, { useEffect, useState } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import { Link, useNavigate } from 'react-router-dom';
import companyLogo from '../../../assets/img/dd-logo.png';
import classNames from 'classnames';
import useDarkMode from '../../../hooks/useDarkMode';
import { useFormik } from 'formik';
import { checkOTPService, sendMailForForgetUsernamePassowrdService, updatePasswordService, updateUsernameService } from '../../../common/service/authService';
import { IAuthSignup, IAuthUpdatePassword, IAuthUpdateUsername } from '../../../common/interface/iAuth';
import { storageObj } from '../../../common/default/defaultStorage';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Alert from '../../../components/bootstrap/Alert';
import Spinner from '../../../components/bootstrap/Spinner';
import Label from '../../../components/bootstrap/forms/Label';
import OTPInput from 'react-otp-input';
import { ERROR_MESSAGE, FORGOT_PASSWORD, FORGOT_USERNAME, PROCESSING_MESSAGE } from '../../../common/default/enumCustomMessage';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { displaySwal, TOAST_TYPE, toastAlert } from '../../../common/functions/displaySwal';
import Icon from '../../../components/icon/Icon';
import { lowercaseRegex, numberRegex, passwordRegex, specialCharRegex, uppercaseRegex } from '../../../common/default/regex';

interface IChangeShowComponentProps {
	changeShowComponent: () => void,
	forgotType: string,
}


const updatePasswordFields: IAuthUpdatePassword = {
	id: 0,
	password: '',
	confirm_password: ''
}

let OTP_ID = 0;



const ForgotPassword = ({ changeShowComponent, forgotType }: IChangeShowComponentProps) => {

	// const [updateUsernameFormFields, setUpdateUsernameFormFields] = useState<IAuthUpdateUsername>({ ...updateUsernameFields });
	const [updatePasswordFormFields, setUpdatePasswordFormFields] = useState<IAuthUpdatePassword>({ ...updatePasswordFields });
	const [showAlert, setShowAlert] = useState<boolean>(false);
	const [alertMessage, setAlertMessage] = useState<string>('');
	const [isProcessing, setIsProcessing] = useState<boolean>(false);
	const [otp, setOtp] = useState('');
	const [otpValidationStatus, setOtpValidationStatus] = useState<boolean>(false);
	const [httpCallStatus, setHttpCallStatus] = useState<boolean>(false);
	const [showOtp, setShowOtp] = useState<boolean>(false);
	// const [usernameUpdateStatus, setUsernameUpdateStatus] = useState<boolean>(false);
	const [passwordUpdateStatus, setPasswordUpdateStatus] = useState<boolean>(false);
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [inputDisabled, setInputDisabled] = useState<boolean>(true);
	// const [isMailSending, setIsMailSending] = useState<boolean>(false);

	const { darkModeStatus } = useDarkMode();
	const { t } = useTranslation();

	const successTitle = t('great');
	const errorTitle = t('gosh')
	const successButtonText = t('GenericMessage.ok');
	const navigate = useNavigate()

	// Handle Button on OTP inputs.. 
	const HandleOTPInputs = (value: any) => {
		setOtp(value);
		setInputDisabled(value.length < 6);
	}

	const formikObj = useFormik({
		enableReinitialize: true,
		initialValues: {
			signUpEmail: '',
		},
		validate: (values) => {
			const errors: { signUpEmail?: string} = {};

			if (forgotType === FORGOT_USERNAME) {
				if (!values.signUpEmail || !values.signUpEmail.includes('@')){
					errors.signUpEmail = t('notValidEmail')||'';
					return errors;
				}
			}
			else {
				if (!values.signUpEmail) {
					errors.signUpEmail = t('emailOrUsernameRequired')||'';
					return errors;
				}
			}
			 
		},
		validateOnChange: false,
		onSubmit: (values) => {
			const req = { email: values.signUpEmail }
			setShowAlert(true)
			setAlertMessage(PROCESSING_MESSAGE);
			sendConfirmationMail(req);
		}
	})

	// useEffect(()=>{
	// 	if(forgotType === FORGOT_PASSWORD && email){
	// 		sendConfirmationMail({email:email})
	// 		setShowOtp(true)
	// 	}
	// },[])

	// const formikObjUsername = useFormik({
	// 	enableReinitialize: true,
	// 	initialValues: updateUsernameFormFields,
	// 	validateOnChange: false,
	// 	validationSchema: Yup.object({
	// 		username: Yup.string().required('Required'),
	// 		confirm_username: Yup.string()
	// 			.oneOf([Yup.ref('username'), ''], 'Username must match')
	// 	}),
	// 	onSubmit: (values) => {
	// 		setIsProcessing(true);
	// 		setAlertMessage(PROCESSING_MESSAGE);
	// 		values.id = OTP_ID;
	// 		updateUsernameService(values)
	// 			.then((res: any) => {
	// 				if (res.success == 1) {
	// 					setAlertMessage(res.message);
	// 					setUsernameUpdateStatus(true);
	// 				} else {
	// 					setAlertMessage(res.message);
	// 					setUsernameUpdateStatus(false);
	// 				}
	// 			})
	// 			.catch(err => {
	// 				setAlertMessage(ERROR_MESSAGE);
	// 				setUsernameUpdateStatus(false);
	// 			})
	// 			.finally(() => {
	// 				setIsProcessing(false);
	// 			})
	// 	}
	// })

	const formikObjPassword = useFormik({
		enableReinitialize: true,
		initialValues: updatePasswordFormFields,
		validateOnChange: false,
		validationSchema: Yup.object({
			password: Yup.string().required('Required')
				.min(8, 'Password should be minimum of 8 characters')
				.matches(/[A-Z]/, "Atleast use one UpperCase")
				.matches(/[a-z]/, "Atleast use one  LowerCase")
				.matches(/[0-9]/, "Atleast use one  Number")
				.matches(/[^\w]/, "Atleast use one  Special Character"),
			confirm_password: Yup.string()
				.required('Required')
				.oneOf([Yup.ref('password'), ''], 'Password must match')
		}),
		onSubmit: (values) => {
			setIsProcessing(true);
			values.id = OTP_ID;
			updatePasswordService(values)
				.then((res: any) => {
					if (res.success == 1) {
						toastAlert(TOAST_TYPE.SUCCESS, res?.message);
						setTimeout(() => {
							setPasswordUpdateStatus(true); 
							changeShowComponent();
						}, 2000);
					} else {
						toastAlert(TOAST_TYPE.ERROR, res?.message);
						setPasswordUpdateStatus(false);
					}
				})
				.catch((err: Error) => {
					toastAlert(TOAST_TYPE.ERROR, err?.message);
					setPasswordUpdateStatus(false);
				})
				.finally(() => {
					setIsProcessing(false);
				})
		}
	})

	const sendConfirmationMail = (req: IAuthSignup) => {
		setIsProcessing(true);
		sendMailForForgetUsernamePassowrdService({...req,email:req?.email?.toLowerCase()}).then((res: any) => {
			if (res.success == 1) {
				setShowOtp(true);
				toastAlert(TOAST_TYPE.SUCCESS, res?.message);

			} else {
				setShowOtp(false);
				toastAlert(TOAST_TYPE.ERROR, res?.message);
			}
		}).catch((err: Error) => {
			toastAlert(TOAST_TYPE.ERROR, err?.message);
			setShowOtp(false);
		}).finally(() => {
			setShowAlert(true);
			setIsProcessing(false);
		})
	}

	const verifyOtp = () => {
		setIsProcessing(true);
		const req = {
			email: formikObj.values.signUpEmail,
			otp: otp,
			type: forgotType === FORGOT_USERNAME ? 1 : 2
		}
		checkOTPService(req).then((res: any) => {
			if (res.success == 1) {
				toastAlert(TOAST_TYPE.SUCCESS, res?.message);
				if (forgotType === FORGOT_USERNAME) {
					changeShowComponent();
				} else {
					setOtpValidationStatus(true); 
					OTP_ID = res.data.id;
				}
			} else {
				if (forgotType === FORGOT_USERNAME) {
					toastAlert(TOAST_TYPE.ERROR, res?.message);
				} else {
					toastAlert(TOAST_TYPE.ERROR, res?.message);
					setOtpValidationStatus(false);
				}

			}
		})
			.catch(err => {
				if (forgotType === FORGOT_USERNAME) {
					toastAlert(TOAST_TYPE.ERROR, err?.message);
				} else {
					toastAlert(TOAST_TYPE.ERROR, err?.message);
					setOtpValidationStatus(false);
				}
			})
			.finally(() => {
				setIsProcessing(false);
				setOtp('');
			})
	}

	useEffect(() => {
		if (passwordUpdateStatus) {
			setTimeout(() => {
				changeShowComponent()
			}, 3000);
		}
	}, [passwordUpdateStatus, changeShowComponent])
	return (
		<PageWrapper
			className={classNames('bg-warning')}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>

								<div className='text-center my-3'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<img alt='logo' style={{ width: 200 }} src={String(companyLogo)} />
									</Link>
								</div>

								{showOtp ?
									<React.Fragment>
										{!otpValidationStatus &&
											<>
												<div className='d-flex justify-content-center mb-4'>
													<p className='text-center fs-4 text-blue'><strong>{t('pleaseEnterOTPreceivedInMail')}</strong></p>
												</div>

												<OTPInput
													value={otp}
													onChange={HandleOTPInputs}
													numInputs={6}
													renderSeparator={<span>-</span>}
													renderInput={(props) => <input {...props} />}
													inputStyle={{
														width: '2em',
														textAlign: 'center',
														height: '2em',
														fontSize: '20px',
														fontWeight: 700
													}}
													containerStyle={{
														justifyContent: "center"
													}}
												/>
												<div className='col-12 mt-4 d-flex justify-content-center'>
													<Button
														color='secondary'
														className='w-25 py-3 me-3'
														onClick={() => changeShowComponent()}
													>
														{t('cancel')}
													</Button>
													<Button
														color='info'
														className='w-25 py-3'
														onClick={() => verifyOtp()}
														isDisable={isProcessing || inputDisabled}
													>
														{isProcessing && (
															<Spinner isSmall inButton isGrow />
														)}
														{t('verifyOTP')}
													</Button>
												</div>
											</>
										}
									</React.Fragment>
									:
									<div>
										{/* <Label className='fw-bold d-flex justify-content-end'>{t('forgotUsername?')}</Label> */}
										{(forgotType === FORGOT_USERNAME) ? <Label className='fw-bold d-flex justify-content-center'>{t('forgotUsername?')}</Label> : <Label className='fw-bold d-flex justify-content-center'>{t('forgotPassword?')}</Label>}
										<React.Fragment>
											<div className={classNames(
												'col-12',
												// {
												// 	'd-none': forgotType === FORGOT_PASSWORD,
												// }
											)}>
												{(forgotType === FORGOT_USERNAME) ? <Label className='ms-1'>{t('email') || ''} <span className='text-danger'>*</span></Label> : <Label className='ms-1'>{t('emailOrUsername') || ''} <span className='text-danger'>*</span></Label>}
												<FormGroup
													id='signUpEmail'
												// isFloating
												// label='Your email'
												>
													<Input type='email'
														autoComplete='email'
														value={formikObj.values.signUpEmail}
														isTouched={formikObj.touched.signUpEmail}
														invalidFeedback={
															formikObj.errors.signUpEmail
														}
														isValid={formikObj.isValid}
														onChange={formikObj.handleChange}
														onBlur={formikObj.handleBlur}
														onFocus={() => {
															formikObj.setErrors({});
														}}
														onKeyDown={(e: any) => e.key == 'Enter' && formikObj.handleSubmit()}
													// disabled ={ forgotType === FORGOT_PASSWORD}
													/>
												</FormGroup>
											</div>

											<div className='col-12 mt-4 d-flex justify-content-center'>
												<Button
													color='info'
													className='w-50 py-3'
													onClick={formikObj.handleSubmit}
													isDisable={isProcessing}
												>
													{isProcessing && (
														<Spinner isSmall inButton isGrow />
													)}
													{/* {(forgotType === FORGOT_USERNAME) ? 'Send Email To Reset Username' : 'Send Email To Reset Password'} */}
													{t('verifyAccount')}
												</Button>
											</div>
											<div className='mt-4 d-flex justify-content-end me-2'>
												<p>{t('please')}{' '}<b className='text-info cursor-pointer' onClick={() => changeShowComponent()}><i>{t('clickHereLogin')}</i></b>{' '}{t('toLogin')}</p>
											</div>
										</React.Fragment>
									</div>
								}

								{(forgotType === FORGOT_PASSWORD) &&
									<React.Fragment>
										{otpValidationStatus &&
											<React.Fragment>
												{!passwordUpdateStatus ?
													<div>
														<p className='text-center fs-4 text-blue'><strong>{t('updatePassword')}</strong></p>
														<div className='my-4'>
															<Label className='ms-1'>{t('newPassword') || ''} <span className='text-danger'>*</span></Label>
															<div className='row position-relative'>
																<FormGroup
																	id='password'
																	// isFloating
																	className='d-inline-block'
																>
																	<Input type={showPassword ? 'text' : 'password'}
																		autoComplete='off'
																		value={formikObjPassword.values.password}
																		isTouched={formikObjPassword.touched.password}
																		// invalidFeedback={
																		// 	formikObjPassword.errors.password
																		// }
																		isValid={formikObjPassword.isValid}
																		onChange={formikObjPassword.handleChange}
																	// onBlur={formikObjPassword.handleBlur}
																	// onFocus={() => {
																	// 	formikObjPassword.setErrors({});
																	// }}
																	/>
																</FormGroup>
																<FormGroup>
																	<Button
																		className='position-absolute'
																		style={{
																			top: '2px',
																			right: '12px',
																		}}
																		onClick={() => setShowPassword(!showPassword)}
																		icon={!showPassword ? 'VisibilityOff' : 'Visibility'}>
																	</Button>
																</FormGroup>
															</div>
														</div>

														<div>
															<p>{t('pwdCriteria')}</p>
															<ul>
																<li>
																	<small className='mb-0'>
																		{t('8charPwd')}
																		{formikObjPassword.values.password.length >= 8 &&
																			<Icon
																				className='ms-2 fs-4'
																				icon={'Check'}
																				color={'success'}
																				size={'lg'}
																				forceFamily={'material'}
																			/>
																		}
																	</small>
																</li>
																<li>
																	<small className='mb-0'>
																		{t('uppercasePwd')}
																		{uppercaseRegex.test(formikObjPassword.values.password) &&
																			<Icon
																				className='ms-2 fs-4'
																				icon={'Check'}
																				color={'success'}
																				size={'lg'}
																				forceFamily={'material'}
																			/>
																		}
																	</small>
																</li>
																<li>
																	<small className='mb-0'>
																		{t('lowercasePwd')}
																		{lowercaseRegex.test(formikObjPassword.values.password) &&
																			<Icon
																				className='ms-2 fs-4'
																				icon={'Check'}
																				color={'success'}
																				size={'lg'}
																				forceFamily={'material'}
																			/>
																		}
																	</small>
																</li>
																<li>
																	<small className='mb-0'>
																		{t('numberPwd')}
																		{numberRegex.test(formikObjPassword.values.password) &&
																			<Icon
																				className='ms-2 fs-4'
																				icon={'Check'}
																				color={'success'}
																				size={'lg'}
																				forceFamily={'material'}
																			/>
																		}
																	</small>
																</li>
																<li>
																	<small className='mb-0'>
																		{t('spCharPwd')}
																		{specialCharRegex.test(formikObjPassword.values.password) &&
																			<Icon
																				className='ms-2 fs-4'
																				icon={'Check'}
																				color={'success'}
																				size={'lg'}
																				forceFamily={'material'}
																			/>
																		}
																	</small>
																</li>
															</ul>
														</div>

														<Label className='ms-1 mt-4'>{t('confirmPassword') || ''} <span className='text-danger'>*</span></Label>
														<div className='row'>
															<FormGroup
																id='confirm_password'
															// isFloating
															>
																<Input type='password'
																	autoComplete='new-password'
																	value={formikObjPassword.values.confirm_password}
																	isTouched={formikObjPassword.touched.confirm_password}
																	invalidFeedback={
																		formikObjPassword.errors.confirm_password
																	}
																	isValid={formikObjPassword.isValid}
																	onChange={formikObjPassword.handleChange}
																	onBlur={formikObjPassword.handleBlur}
																	onFocus={() => {
																		formikObjPassword.setErrors({});
																	}}
																	onKeyDown={(e: any) => e.key == 'Enter' && formikObjPassword.handleSubmit()}
																/>
															</FormGroup>
														</div>

														<div className='col-12 mt-4 d-flex justify-content-center'>
															<Button
																color='info'
																className='w-50 py-3'
																onClick={formikObjPassword.handleSubmit}
																// isDisable={isProcessing}
																isDisable={!passwordRegex.test(formikObjPassword.values.password) || isProcessing}
															>
																{isProcessing && (
																	<Spinner isSmall inButton isGrow />
																)}
																{t('Update')}
															</Button>
														</div>
													</div>
													:
													<div className='mt-4 d-flex justify-content-end'>
														<Link to='#' style={{ textDecoration: 'none' }}>
															<h5>{t('redirectingToLogin')}</h5>
														</Link>
													</div>
												}
											</React.Fragment>
										}
									</React.Fragment>
								}

							</CardBody>
						</Card>

						<div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3 link-dark')}>
								{t('privacypolicy')}
							</a>
							<a
								href='/'
								className={classNames('link-dark text-decoration-none link-light')}>
								{t('termsofuse')}
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default ForgotPassword;

