import React from 'react';
import Spinner from './bootstrap/Spinner';
import { useTranslation } from 'react-i18next';

const Loading = () => {
    const { t } = useTranslation();

    return (
        <div className='d-flex justify-content-center mb-3 mt-3'>
            <Spinner color="primary" isGrow></Spinner>
            <span className='ms-2'>
                {t('GenericMessage.loading')}...
            </span>
        </div>
    )
}

export default Loading;