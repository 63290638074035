import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { toast } from 'react-toastify';
// const { t } = useTranslation();
// const sure = t("GenericMessage.areYouSure");
// const text = t("GenericMessage.wontRevert");
export const displaySwal = (inputTitle: string, inputText: string, success: number, confirmButtonText?: string) => {
    return Swal.fire({
        title: inputTitle,
        text: inputText,
        showConfirmButton: true,
        confirmButtonText: confirmButtonText,
        icon: success === 1 ? 'success' : 'error'
    })
}

export const confirmSwal = (confirmButtonText: string) => {

    return Swal.fire({
        title: 'Are You Sure?',
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: confirmButtonText
    })
}

export const changePass = (confirmButtonText: string) => {

    return Swal.fire({
        title: 'Welcome',
        text: "Thank you for logging in! Your password has been created by your parent, would you like to change it now?",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: confirmButtonText
    })
}

export const TOAST_TYPE = {
    SUCCESS: "success",
    ERROR: "error",
    INFO: "info",
    WARNING: "warning"
}


export const toastAlert = (type: string, msg: string) => {
    if (type == TOAST_TYPE.SUCCESS) {
        return toast.success(msg);
    }
    else if (type == TOAST_TYPE.ERROR) {
        return toast.error(msg);
    }
    else if (type == TOAST_TYPE.WARNING) {
        return toast.warn(msg);
    }
    else {
        return toast.info(msg);
    }

}