import React, { ReactNode, useContext, useState, useEffect } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Brand from '../../../layout/Brand/Brand';
import Navigation, { NavigationLine } from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import { adminDashboardPagesMenu, childDashboardPagesMenu, dashboardPagesMenu, demoPagesMenu, extraMenu, pageLayoutTypesPagesMenu, childExtraMenu } from '../../../menu';
import ThemeContext from '../../../contexts/themeContext';
import Icon from '../../../components/icon/Icon';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import Popovers from '../../../components/bootstrap/Popovers';
import { UserProfileType } from '../../../common/enum/enumUserProfileTypes';
import { storageObj } from '../../../common/default/defaultStorage';
import { fetchUserBasicInformationService } from '../../../common/service/authService';
import { UserInfoContext } from '../../../App/App';
import { changePass, displaySwal } from '../../../common/functions/displaySwal';
import ChangePasswordModal from '../../presentation/common/ChangePasswordModal';


const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const [userTypeId, setUserTypeId] = useState<any>();
	const [doc, setDoc] = useState(
		localStorage.getItem('facit_asideDocStatus') === 'true' || false,
	);

	const { t } = useTranslation(['translation', 'menu']);
	const [username, setUsername] = useState(null);
	const [passwordModal,setPasswordModal] = useState(false)
	const [userId,setUserId] = useState()
	const [flag,setFlag] = useState(0)
	const userInfoDataContext: any = useContext(UserInfoContext);


	const successTitle = t('GenericMessage.success');
    const errorTitle = t('GenericMessage.sorry')
    const successButtonText = t('GenericMessage.ok');
    const deleteText = t('Deleted!');


	useEffect(() => {
		if (!!storageObj.getToken()) {
			fetchUserBasicInformationService().then((res: any) => {
				if (res.success == 1) {
					setUserTypeId(res.data?.user_type_id);
					setUsername(res?.data?.username);
					setUserId(res?.data?.id)
					setFlag(flag+1)
					// if(res?.data?.is_first_time_login){
						if(res?.data?.is_first_time_login==1 && res.data?.user_type_id==UserProfileType.Child && flag==1){
							changePass(t('yes')).then((result:any) => {
								if (result.isConfirmed) {
									// <ChangePasswordModal passwordModalOpen={passwordModalOpen} user_type_id={UserProfileType.Child} user_id={childEditId} passwordModalClose={() => setPasswordModalOpen(false)} />
									setPasswordModal(true)
								}else{
									setPasswordModal(false)
								}
							})
						}
				}
				else {
					setUsername(null);
				}
			})
				.catch(err => {
					setUsername(null);
				})

		}
	},[])

	return (
		<Aside className={UserProfileType.Parent == userTypeId ? 'aside-parent-dashboard' : UserProfileType.Admin == userTypeId ? 'aside-admin-dashboard' : ''}>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			{UserProfileType.Parent == userTypeId &&
				<AsideBody
					// className={classNames({
					// 	'menu-blur-wrapper blur': !!username === false
					// })}
				>
					<div >
						<Navigation menu={dashboardPagesMenu} id='aside-dashboard' />
						<NavigationLine />
						{!doc && (
							<>
								<Navigation menu={extraMenu} id='aside-demo-pages' />
								<NavigationLine />
							</>
						)}
					</div>
					{/* Start design for disabled navigation */}
					{/* <div className='sidebar-blur'>
						<div className='why-disabled'>
						<p>{t("disabledMenu")} <span>{t("setupIsCompleted")}</span></p>
						</div>
					</div> */}
				</AsideBody>
			}
			{UserProfileType.Child == userTypeId &&
				<AsideBody 
				className={classNames({
					'menu-blur-wrapper blur': !!userInfoDataContext?.username === false
				})}
				>
					<div >
						<Navigation menu={childDashboardPagesMenu} id='aside-dashboard' />
						<NavigationLine />
						{!doc && (
							<>
								<Navigation menu={childExtraMenu} id='aside-demo-pages' />
								<NavigationLine />
							</>
						)}
					</div>
					{/* Start design for disabled navigation */}
					{/* <div className='sidebar-blur'>
						<div className='why-disabled'>
							<p>{t("disabledMenu")} <span>{t("setupIsCompleted")}</span></p>
						</div>
					</div> */}
				</AsideBody>
			}
			{UserProfileType.Admin == userTypeId &&
				<AsideBody>
					<Navigation menu={adminDashboardPagesMenu} id='aside-dashboard' />
					<NavigationLine />
				</AsideBody>
			}
			{passwordModal && <ChangePasswordModal passwordModalOpen={passwordModal} user_type_id={UserProfileType.Child} user_id={userId} passwordModalClose={() => setPasswordModal(false)} />}
			<AsideFoot>
				<nav aria-label='aside-bottom-menu'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								localStorage.setItem('facit_asideDocStatus', String(!doc));
								// setDoc(!doc);
							}}
							data-tour='documentation'>
						</div>
					</div>
				</nav>
				<User />
			</AsideFoot>
		</Aside>

		

	);
};

export default DefaultAside;
