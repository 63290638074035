import { createSlice } from '@reduxjs/toolkit'
import { DashboardType } from '../enum/enumDashboardType'

const initialState = {
    value: DashboardType.HouseholdDashboard,
    status: 'householdDashboard'
}

export const dashboardToggleSlice: any = createSlice({
    name: 'dashboardToggle',
    initialState,
    reducers: {
        toggleHouseholdDashboard: (state) => { state.value = DashboardType.HouseholdDashboard },
        toggleSetupDashboard: (state) => { state.value = DashboardType.SetupDashboard },
    }
})

export const { toggleHouseholdDashboard, toggleSetupDashboard } = dashboardToggleSlice.actions;

export const defaultDashboardToggle = (state: any) => state.dashboard_Reducer.value;

export default dashboardToggleSlice.reducer;