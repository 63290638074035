class STORAGE {

    AUTH_TOKEN = 'AUTH_TOKEN';

    NEW_USER = 'NEW_USER'

    setUsername(param: string) {
        sessionStorage.setItem('username', param);
    }

    getUsername(): string | null {
        return sessionStorage.getItem('username');
    }

    setUserTypeId(param: string) {
        sessionStorage.setItem('userTypeId', param);
    }

    getUserTypeId(): string | null {
        return sessionStorage.getItem('userTypeId');
    }
    setRelationType(param: string) {
        sessionStorage.setItem('userRelationType', param);
    }

    getRelationType(): string | null {
        return sessionStorage.getItem('userRelationType');
    }

    setUserAuthId(param: string) {
        sessionStorage.setItem('userAuthId', param);
    }

    getUserAuthId(): string | null {
        return sessionStorage.getItem('userAuthId');
    }

    setUserRegDate(param: string) {
        sessionStorage.setItem('userRegDate', param);
    }

    getUserRegDate(): string | null {
        return sessionStorage.getItem('userRegDate');
    }

    setUserData(param: any) {
        sessionStorage.setItem('userData', JSON.stringify(param));
    }

    getUserData(): any {
        return JSON.parse(sessionStorage.getItem('userData') as string);
    }

    setToken(param: string) {
        sessionStorage.setItem(this.AUTH_TOKEN, param);
    }

    removeToken() {
        sessionStorage.removeItem(this.AUTH_TOKEN)
    }

    getToken(): string | null {
        return sessionStorage.getItem(this.AUTH_TOKEN);
    }

    setNewUser(param: boolean) {
        sessionStorage.setItem(this.NEW_USER, JSON.stringify(param));
    }

    getNewUser() {
        return JSON.parse(sessionStorage.getItem(this.NEW_USER) as string);
    }

    setUserEmail(param: string) {
        sessionStorage.setItem('email', param);
    }

    getUserEmail() {
        return sessionStorage.getItem('email');
    }

    setFamilyCode(param: string) {
        sessionStorage.setItem('familyCode', param);
    }

    getFamilyCode() {
        return sessionStorage.getItem('familyCode');
    }

    setImpersonate(param: any) {
        sessionStorage.setItem("impersonate", JSON.stringify(param));
    }

    getImpersonate(): boolean {
        return JSON.parse(sessionStorage.getItem('impersonate') as any);
    }

    setImpersonateBtn(param: any) {
        sessionStorage.setItem("impersonateBtn", JSON.stringify(param));
    }

    getImpersonateBtn(): boolean {
        return JSON.parse(sessionStorage.getItem('impersonateBtn') as any);
    }

    setActivitySelectDate(param: any) {
        sessionStorage.setItem('activitySelectDate', JSON.stringify(param));
    }

    getActivitySelectDate() {
        return JSON.parse(sessionStorage.getItem('activitySelectDate') as any);
    }

    clearStorage() {
        sessionStorage.clear();
        localStorage.clear();
    }

}

export const storageObj = new STORAGE();