import React, { useLayoutEffect, forwardRef, ReactElement, useContext, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ISubHeaderProps } from '../SubHeader/SubHeader';
import { IPageProps } from '../Page/Page';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../contexts/authContext';
import { demoPagesMenu } from '../../menu';
import { storageObj } from '../../common/default/defaultStorage';
import { IAuthLogin } from '../../common/interface/iAuth';
import { ENDPOINT, axiosInstance } from '../../api';
import { loginService, validateEmailLoginService } from '../../common/service/authService';

interface IPageWrapperProps {
	isProtected?: boolean;
	title?: string;
	description?: string;
	children:
	| ReactElement<ISubHeaderProps>[]
	| ReactElement<IPageProps>
	| ReactElement<IPageProps>[];
	className?: string;
}
const PageWrapper = forwardRef<HTMLDivElement, IPageWrapperProps>(
	({ isProtected, title, description, className, children }, ref) => {

		const { user } = useContext(AuthContext);
		const navigate = useNavigate();
		const handleOnClick = useCallback(() => navigate('/dashboard'), [navigate]);

		useLayoutEffect(() => {
			// @ts-ignore
			// document.getElementsByTagName('TITLE')[0].text = `${title ? `${title} | ` : ''}${process.env.REACT_APP_SITE_NAME
			// 	}`;
			document.getElementsByTagName('TITLE')[0].text = `${title ? `${title} ` : ''}`;
			// @ts-ignore
			document
				?.querySelector('meta[name="description"]')
				.setAttribute('content', description || process.env.REACT_APP_META_DESC || '');
		});

		// sample mail url = 'http://localhost:3000/diligent-dollar/authenticate/cG90dW1vai5oZXF1cW9AcnVuZ2VsLm5ldA==/MTIzNDU2';
		// 					 'http://localhost:3000/welcome/diligent-dollar/dGVzdDEyMzMz/QWRtaW5AMTIzNA==/1458

		useEffect(() => {
			if (isProtected && user === '') {
				const url = window.location.href;
				const splitUrl = url.split('/')
				const username = splitUrl[splitUrl.length - 3];
				const password = splitUrl[splitUrl.length - 2];
				const user_id = splitUrl[splitUrl.length - 1];

				if (url.includes('/authenticate/')) {
					const req = {
						email: username,
						pass: password,
						user_id: user_id
					}
				
					validateEmailLoginService(req).then((res: any) => {
						if (res.success == 1) {
							handleOnClick();
							storageObj.setUserEmail(res?.data?.email);
							storageObj.setToken(res?.token);
							storageObj.setUserTypeId(res?.data?.user_type_id)
							storageObj.setUserAuthId(res?.data?.id)
							storageObj.setUserRegDate(res?.data?.created_at?.split(" ")[0])
							storageObj.setUserData(res?.data)
							storageObj.setFamilyCode(res?.data?.family_code)
							storageObj.setRelationType(res?.data?.relation_type)
							
						} else {
							navigate(`../${demoPagesMenu.login.path}`);
						}
					})
						.catch(err => {

						})

				}
				else {
					if(url.includes('/welcome/')){
						sessionStorage.setItem('user', atob(username));
						sessionStorage.setItem('password', atob(password));
						navigate(`../${demoPagesMenu.login.path}`);
					}
				}
			}
			return () => { };
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		return (
			<div ref={ref} className={classNames('page-wrapper', 'container-fluid', className)}>
				{children}
			</div>
		);
	},
);
PageWrapper.displayName = 'PageWrapper';
PageWrapper.propTypes = {
	isProtected: PropTypes.bool,
	title: PropTypes.string,
	description: PropTypes.string,
	// @ts-ignore
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
PageWrapper.defaultProps = {
	isProtected: true,
	title: undefined,
	description: undefined,
	className: undefined,
};

export default PageWrapper;
