import React from 'react'
import { IAuthEmailVerify, IAuthLogin, IAuthLoginUserName, IAuthSignup, IAuthUpdatePassword, IAuthUpdateUsername, IAuthVerifyOTP, IAuthValidateMobileNumber, IChangePassword } from '../interface/iAuth';
import { ENDPOINT, axiosInstance } from '../../api';

export const loginService = async (req: IAuthLogin) => {
    const res = await axiosInstance.post(ENDPOINT.AUTH.LOGIN, req);
    return res;
}

export const logoutService = async () => {
    const res = await axiosInstance.post(ENDPOINT.AUTH.LOGOUT);
    return res;
}

export const signUpWithEmailService = async (req: IAuthSignup) => {
    const res = await axiosInstance.post(ENDPOINT.AUTH.USER_REGISTRATION, req);
    return res;
}
export const loginOtpService = async (req: any) => {
    const res = await axiosInstance.post(ENDPOINT.AUTH.SEND_MAIL_LOGIN_OTP, req);
    return res;
}

export const validateUserDataService = async (req: IAuthLoginUserName) => {
    const res = await axiosInstance.post(ENDPOINT.AUTH.VALIDATE_USER_DATA, req);
    return res;
}

export const validateEmailLoginService = async(req: IAuthEmailVerify) =>{
    const res = await axiosInstance.post(ENDPOINT.AUTH.EMAIL_LOGIN_AUTHENTICATE, req);
    return res;
}

export const sendMailForForgetUsernamePassowrdService = async(req: IAuthSignup) =>{
    const res = await axiosInstance.post(ENDPOINT.AUTH.FORGET_USERNAME_PASSWORD, req);
    return res;
}

export const updateUsernameService = async (req: IAuthUpdateUsername) =>{
    const res = await axiosInstance.post(ENDPOINT.AUTH.UPDATE_USERNAME, req);
    return res;
}

export const updatePasswordService = async (req: IAuthUpdatePassword) =>{
    const res = await axiosInstance.post(ENDPOINT.AUTH.UPDATE_PASSWORD, req);
    return res;
}

export const checkOTPService = async (req: IAuthVerifyOTP) =>{
    const res = await axiosInstance.post(ENDPOINT.AUTH.CHECK_OTP, req);
    return res;
}
export const validateMobileNumberService = async (req: IAuthValidateMobileNumber) => {
    const res = await axiosInstance.post(ENDPOINT.AUTH.VALIDATE_MOBILE_NUMBER, req);
    return res;
}
export const fetchUserBasicInformationService = async(req?:number|any) => {
    const res = await axiosInstance.post(ENDPOINT.AUTH.FETCH_USER_INFORMATION, req);
    return res;
}
export const fetchRelationshipService = async(req: any) => {
    const res = await axiosInstance.post(ENDPOINT.AUTH.FETCH_RELATIONSHIP, req);
    return res;
}
export const inviteParentService = async(req: any) => {
    const res = await axiosInstance.post(ENDPOINT.AUTH.INVITE_PARENT_BEFORE_LOGIN, req);
    return res;
}
export const validateEmailService = async(req: any) => {
    const res = await axiosInstance.post(ENDPOINT.AUTH.VALIDATE_EMAIL, req);
    return res;
}
export const changePasswordService = async (req: IChangePassword) =>{
    const res = await axiosInstance.post(ENDPOINT.AUTH.CHANGE_PASSWORD, req);
    return res;
}
export const inviteMyParentService = async(req: any)=>{
    const res = await axiosInstance.post(ENDPOINT.AUTH.INVITE_PARENT, req);
    return res;
}

