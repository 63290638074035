import React, { SVGProps } from 'react';

const SvgCustomSpain = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 512 512'
		xmlSpace='preserve'
		width='1em'
		height='1em'
		className='svg-icon'
		{...props}>
		<rect width='512' height='170.67' style={{ fill: '#d6002a' }} />
		<rect y='170.67' width='512' height='170.67' style={{ fill: '#ffb61e' }} />
		<rect y='341.33' width='512' height='170.67' style={{ fill: '#d6002a' }} />
	</svg>
);

export default SvgCustomSpain;
