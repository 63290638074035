import axios from 'axios';
import { storageObj } from '../common/default/defaultStorage';
import { ENDPOINT } from '.';


const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API
});

axiosInstance.interceptors.request.use(async function (config) {

  if (storageObj.getToken()) {
      config.headers.Authorization = `Bearer ${storageObj.getToken()}`;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

async function callRefreshToken(originalRequest) {
  try {
      const res = await axiosInstance.get(ENDPOINT.AUTH.REFRESH_TOKEN);
      storageObj.setToken(res.token);
      return await axiosInstance(originalRequest);
  } catch (error) {
      storageObj.removeToken();
  }
}

axiosInstance.interceptors.response.use(async function (response) {
  const originalRequest = response.config;
  if (response?.data?.errors?.[0]?.message === 'Unauthenticated.' && !originalRequest._retry) {
      originalRequest._retry = true;
      return callRefreshToken(originalRequest);
  }
  return response.data;
}, async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      await callRefreshToken(originalRequest);
  } else {
      if (error.response.data)
          return Promise.reject({ ...error.response.data, status: error.response.status });
      return Promise.reject({ msg: "Oops! Something Went wrong", status: error.response.status });
  }

});



export default axiosInstance;