import React, { useState, useContext, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { demoPagesMenu } from '../../menu';
import useDarkMode from '../../hooks/useDarkMode';
import Collapse from '../../components/bootstrap/Collapse';
import { NavigationLine } from '../Navigation/Navigation';
import Icon from '../../components/icon/Icon';
import useNavigationItemHandle from '../../hooks/useNavigationItemHandle';
import AuthContext from '../../contexts/authContext';
import Popovers from '../../components/bootstrap/Popovers';
// import defaultAvatar from '../../assets/img/defaultAvatar.png';
import defaultAvatar from '../../assets/img/childStaticimg.png';
import { storageObj } from '../../common/default/defaultStorage';
import { UserProfileType } from '../../common/enum/enumUserProfileTypes';
import { logoutService } from '../../common/service/authService';
import { TOAST_TYPE, toastAlert } from '../../common/functions/displaySwal';

const User = () => {
	const { userData, setUser } = useContext(AuthContext);
	const value = useContext(AuthContext);
	const [userInfo, setUserInfo] = useState<any>();
	const [userName, setUserName] = useState<any>()
	const navigate = useNavigate();
	const handleItem = useNavigationItemHandle();

	useEffect(() => {
		setTimeout(() => {
			setUserInfo(storageObj.getUserData())
			setUserName(storageObj.getUsername())
		}, 2000);
	}, [userInfo])

	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const [collapseStatus, setCollapseStatus] = useState<boolean>(false);

	const { t } = useTranslation(['translation', 'menu']);

	const logOut = () => {
		logoutService().then((res: any)=>{
			if(res.success == 1){
				storageObj.clearStorage();
				navigate(`../${demoPagesMenu.home.path}`);
				if (setUser) {
					setUser('');
				}
				toastAlert(TOAST_TYPE.SUCCESS, res.message);
			}
			else {
				toastAlert(TOAST_TYPE.ERROR, res.message);
			}
		})
		.catch((err: Error)=> toastAlert(TOAST_TYPE.ERROR, err.message))
		// navigate(`../${demoPagesMenu.login.path}`);
	}

	return (
		<>
			<div
				className={classNames('user', { open: collapseStatus })}
				role='presentation'
				onClick={() => setCollapseStatus(!collapseStatus)}>
				<div className='user-avatar'>
					<img src={!!userInfo?.full_profile_picture_path ? `${userInfo?.full_profile_picture_path}` : String(defaultAvatar)} alt='Avatar' width={132} height={132} />
				</div>
				<div className='user-info'>
					<div className='user-name'>
						{`${!!userInfo?.firstName ? 'Hi! ' + userInfo?.firstName : t('GenericMessage.defaultUser')}` || `${t('GenericMessage.defaultUser')}`}
					</div>
					<div className='user-sub-title'>
					</div>
				</div>
			</div>

			<Collapse isOpen={collapseStatus} className='user-menu'>
				<nav aria-label='aside-bottom-user-menu'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								UserProfileType.Child == userInfo?.user_type_id &&  navigate('/profile');
								UserProfileType.Parent == userInfo?.user_type_id &&  navigate('/parent-profile');
								UserProfileType.Admin == userInfo?.user_type_id &&   navigate('/my-info');
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='AccountBox' className='navigation-icon' />
									<span className='navigation-text'>
										{t('menu:Profile') as ReactNode}
									</span>
								</span>
							</span>
						</div>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								setDarkModeStatus(!darkModeStatus);
								handleItem();
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon
										icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
										color={darkModeStatus ? 'info' : 'warning'}
										className='navigation-icon'
									/>
									<span className='navigation-text'>
										{darkModeStatus
											? (t('menu:DarkMode') as ReactNode)
											: (t('menu:LightMode') as ReactNode)}
									</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
				<NavigationLine />
				<nav aria-label='aside-bottom-user-menu-2'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => logOut()}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='Logout' className='navigation-icon' />
									<span className='navigation-text'>
										{t('menu:Logout') as ReactNode}
									</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
			</Collapse>
		</>
	);
};

export default User;
