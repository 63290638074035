import React, { lazy } from 'react';
import { adminDashboardPagesMenu, childDashboardPagesMenu, comingSoonPage, dashboardPagesMenu, demoPagesMenu, detailsPagesMenu, extraMenu, childExtraMenu, pageLayoutTypesPagesMenu, setupMenu, usersListMenu, releaseSoonPage } from '../menu';
import Login from '../pages/presentation/auth/Login';
import Landing from '../pages/presentation/auth/Landing';
import { element } from 'prop-types';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/presentation/dashboard/DashboardPage')),
	DASHBOARDPARENT: lazy(() => import('../pages/presentation/dashboard/DashboardParent')),
	DASHBOARDCHILD: lazy(() => import('../pages/presentation/dashboard/DashboardChild')),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};
const PAGE_LAYOUTS = {
	HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),
	HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
	SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
	CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
	BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
	ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
	MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
};
const SETUP_PROFILE_LAYOUTS = {
	CHILD_SETUP: lazy(() => import('../pages/presentation/setup/ChildAddsetup')),
	USER_SETUP: lazy(() => import('../pages/presentation/setup/UserAddSetup')),
	BUDGET_SETUP: lazy(() => import('../pages/presentation/setup/BudgetSetup')),
	CHORE_SETUP: lazy(() => import('../pages/presentation/setup/ChoreCustomSetup')),
	CHORE_INBUILD_SETUP: lazy(() => import('../pages/presentation/setup/ChoreInbuildSetup')),
	FITNESS_SETUP: lazy(() => import('../pages/presentation/setup/FitnessCustomSetup')),
	FITNESS_INBUILD_SETUP: lazy(() => import('../pages/presentation/setup/FitnessInbuildSetup')),
	ACADEMIC_SETUP: lazy(() => import('../pages/presentation/setup/AcademicSetup')),
	ACADEMIC_SETUP_CHILD: lazy(() => import('../pages/presentation/setup/ChildAcademicSetup'))
};
const USERS_LIST_LAYOUTS = {
	CHILD_LIST: lazy(() => import('../pages/presentation/usersList/ChildList')),
	PARENT_USER_LIST: lazy(() => import('../pages/presentation/usersList/ParentUserList')),
	CHORE_PLAN: lazy(() => import('../pages/presentation/usersList/ChorePlan')),
	FITNESS_PLAN: lazy(() => import('../pages/presentation/usersList/FitnessPlan')),
	ACADEMICS: lazy(() => import('../pages/presentation/usersList/Academics')),
	HOUSEHOLD_LIST: lazy(() => import('../pages/presentation/usersList/HouseholdList')),
	ADMIN_PARENT_LIST: lazy(() => import('../pages/presentation/usersList/AdminParentList')),
	ADMIN_CHILD_LIST: lazy(() => import('../pages/presentation/usersList/AdminChildList')),
	SCHOOL_LIST_CHILD: lazy(() => import('../pages/presentation/usersList/ChildSchoolList')),
	ADMIN_REPORTS: lazy(() => import('../pages/presentation/reports/AdminReports'))
}

const CHILD_DASHBOARD_PAGES = {
	MYCHORELIST: lazy(() => import('../pages/presentation/usersList/MyChoreList')),
	PARENTLIST: lazy(() => import('../pages/presentation/usersList/ParentList')),
	MYFITNESSPLANLIST: lazy(() => import('../pages/presentation/usersList/MyFitnessList')),
}

const DETAIL_PAGES = {
	CHORE_DETAILS_CHILD: lazy(() => import('../pages/presentation/householdDetails/child/ChoreDetails')),
	FITNESS_DETAILS_CHILD: lazy(() => import('../pages/presentation/householdDetails/child/FitnessDetailsChild')),
	CHORE_DETAILS_PARENT_VIEW: lazy(() => import('../pages/presentation/householdDetails/parent/ChoreDetailsParentView')),
	FITNESS_DETAILS_PARENT_VIEW: lazy(() => import('../pages/presentation/householdDetails/parent/FitnessDetailsParentView')),
	HOUSEHOLD_DETAILS_SUPERADMIN_VIEW: lazy(() => import('../pages/presentation/householdDetails/admin/HouseholdDetailsSuperAdminView')),
	PARENT_DETAILS_SUPERADMIN_VIEW: lazy(() => import('../pages/presentation/householdDetails/parent/ParentDetailsSuperAdminView')),
	CHILD_DETAILS_SUPERADMIN_VIEW: lazy(() => import('../pages/presentation/householdDetails/child/ChildDetailsSuperAdminView')),
	GRAB_DETAILS_CHILD: lazy(() => import('../pages/presentation/householdDetails/child/GrabDetailsChild')),
	GRAB_DETAILS_PARENT: lazy(() => import('../pages/presentation/householdDetails/parent/GrabDetailsParent')),
	CHILD_WALLET_DETAILS: lazy(() => import('../pages/presentation/dashboard/child/WalletDetails')),
	PARENT_WALLET_DETAILS: lazy(() => import('../pages/presentation/dashboard/parent/ParentWalletDetails')),
	PARENT_DETAILS_VIEW: lazy(() => import('../pages/presentation/common/ParentView')),
	ADMIN_SUBSCRIPTION_REG_REPORT: lazy(() => import('../pages/presentation/reports/SubscriptionRegReport')),
	ADMIN_USER_REG_REPORT: lazy(() => import('../pages/presentation/reports/UserRegReport')),
	ADMIN_CHORE_REPORT: lazy(() => import('../pages/presentation/reports/ChoreReport')),
	ADMIN_FITNESS_REPORT: lazy(() => import('../pages/presentation/reports/FitnessReport')),
}

const EXTRAS = {
	WISHLIST_PARENT: lazy(() => import('../pages/presentation/extras/parent/WishlistParent')),
	WISHLIST_CHILD: lazy(() => import('../pages/presentation/extras/child/WishListChild')),
	GROWTH_TRACKER: lazy(() => import('../pages/presentation/extras/GrowthTracker')),
	GROWTH_DETAILS_PARENT: lazy(() => import('../pages/presentation/extras/parent/GrowthDetails')),
	UPFOR_GRABS_PARENT: lazy(() => import('../pages/presentation/extras/parent/UpForGrabParent')),
	UPFOR_GRABS_CHILD: lazy(() => import('../pages/presentation/extras/child/UpForGrabChild')),
	REWARDS_LIST_PARENT: lazy(() => import('../pages/presentation/extras/parent/Rewards')),
	MYREWARDS_CHILD: lazy(() => import('../pages/presentation/extras/child/MyRewards')),
	REWARDS_DETAILS_PARENT: lazy(() => import('../pages/presentation/extras/parent/RewardsDetails')),
	REWARDS_RECORDS_CHILD: lazy(() => import('../pages/presentation/extras/child/ViewRewardsRecords')),
	REWARDS_DETAILS_CHILD: lazy(() => import('../pages/presentation/extras/child/RewardsDetailsChild')),
	PARENT_NOTIFICATION_LIST: lazy(() => import('../pages/presentation/extras/NotificationList')),
	CHILD_NOTIFICATION_LIST: lazy(() => import('../pages/presentation/extras/ChildNotificationList'))
}

const HOUSEHOLD_PLANS = {
	SET_CATEGORIES_ADMIN: lazy(() => import('../pages/presentation/houseHoldPlans/admin/SetCategoriesAdmin')),
	CHOREPLAN_TEMPLATE_ADMIN: lazy(() => import('../pages/presentation/houseHoldPlans/admin/ChorePlanTemplateAdmin')),
	SET_FITNESS_CATEGORIES_ADMIN: lazy(() => import('../pages/presentation/houseHoldPlans/admin/SetFitnessCategoriesAdmin')),
	SET_FITNESS_TEMPLATE_ADMIN: lazy(() => import('../pages/presentation/houseHoldPlans/admin/FitnessPlanTemplateAdmin')),
	CHORE_ALLOCATION_PARENT: lazy(() => import('../pages/presentation/houseHoldPlans/parent/ChoreAllocationParent')),
	FITNESS_ALLOCATION_PARENT: lazy(() => import('../pages/presentation/houseHoldPlans/parent/FitnessAllocationParent'))
}
const MY_FAMILY = {
	MY_SIBLING: lazy(() => import('../pages/presentation/myFamily/child/MySibling')),
	MY_SIBLING_INFO: lazy(() => import('../pages/presentation/common/ChildView'))
}

const MY_INFO = {
	CHILD_INFO: lazy(() => import('../pages/presentation/myInfo/child/ChildInfo')),
	PARENT_INFO: lazy(() => import('../pages/presentation/myInfo/parent/ParentInfo')),
	ADMIN_INFO: lazy(() => import('../pages/presentation/myInfo/admin/AdminInfo'))
}

const ACADEMICS = {
	CHILD_SCHOOL_INFO: lazy(() => import('../pages/presentation/academics/child/MySchool')),
	CHILD_REPORT_CARD: lazy(() => import('../pages/presentation/academics/child/ReportCardChild')),
	CHILD_REPORT_CARD_UPLOAD: lazy(() => import('../pages/presentation/academics/child/ReportCardUpload')),
	CHILD_REPORT_CARD_PARENT_VIEW: lazy(() => import('../pages/presentation/academics/parent/ChildReportCards')),
	CHILD_REPORT_DETAILS_PARENT_VIEW: lazy(() => import('../pages/presentation/academics/parent/ChildReportCardDetails')),
}

const COMING_SOON_PAGE = {
	COMING_SOON: lazy(() => import('../pages/presentation/ComingSoon'))
}

const RELEASE_NOTES_PAGE = {
	RELEASE_NOTES: lazy(() => import('../pages/presentation/ReleaseNotes'))
}

const MESSAGING_PAGE = {
	MESSAGING: lazy(() => import('../pages/presentation/common/Messaging'))
}

const presentation = [
	/**
	 * Landing
	 */
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	// coming soon
	{
		path: comingSoonPage.path,
		element: <COMING_SOON_PAGE.COMING_SOON />,
	},
	{
		path: releaseSoonPage.release.path,
		element: <RELEASE_NOTES_PAGE.RELEASE_NOTES />
	},
	//---------------------- AUTH MENU ----------------------------------
	{
		path: demoPagesMenu.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		path: demoPagesMenu.home.path,
		element: <Landing />,
	},
	{
		path: demoPagesMenu.login.path,
		element: <Login />,
	},
	{
		path: demoPagesMenu.signUp.path,
		element: <Login isSignUp />,
	},

	// -------------------- PAGE LAYOUT MENU -------------------------------------
	{
		path: pageLayoutTypesPagesMenu.blank.path,
		element: <PAGE_LAYOUTS.BLANK />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.headerAndSubheader.path,
		element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyHeader.path,
		element: <PAGE_LAYOUTS.HEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlySubheader.path,
		element: <PAGE_LAYOUTS.SUBHEADER />,
	},
	{
		path: pageLayoutTypesPagesMenu.pageLayout.subMenu.onlyContent.path,
		element: <PAGE_LAYOUTS.CONTENT />,
	},
	{
		path: pageLayoutTypesPagesMenu.asideTypes.subMenu.defaultAside.path,
		element: <PAGE_LAYOUTS.ASIDE />,
	},
	{
		path: pageLayoutTypesPagesMenu.asideTypes.subMenu.minimizeAside.path,
		element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
	},

	// ----------------------------- ADMIN MENU -------------------------------
	{
		path: adminDashboardPagesMenu.household.subMenu.houseHoldList.path,
		element: <USERS_LIST_LAYOUTS.HOUSEHOLD_LIST />
	},
	{
		path: detailsPagesMenu.HouseholdDetailsSuperAdminView.path,
		element: <DETAIL_PAGES.HOUSEHOLD_DETAILS_SUPERADMIN_VIEW />
	},
	{
		path: detailsPagesMenu.ParentDetailsSuperAdminView.path,
		element: <DETAIL_PAGES.PARENT_DETAILS_SUPERADMIN_VIEW />
	},
	{
		path: detailsPagesMenu.childDetailsSuperAdminView.path,
		element: <DETAIL_PAGES.CHILD_DETAILS_SUPERADMIN_VIEW />
	},
	{
		path: adminDashboardPagesMenu.chorePlans.subMenu.setCategory.path,
		element: <HOUSEHOLD_PLANS.SET_CATEGORIES_ADMIN />
	},
	{
		path: adminDashboardPagesMenu.chorePlans.subMenu.choreTemplate.path,
		element: <HOUSEHOLD_PLANS.CHOREPLAN_TEMPLATE_ADMIN />
	},
	{
		path: adminDashboardPagesMenu.fitnessPlans.subMenu.setCategory.path,
		element: <HOUSEHOLD_PLANS.SET_FITNESS_CATEGORIES_ADMIN />
	},
	{
		path: adminDashboardPagesMenu.fitnessPlans.subMenu.choreTemplate.path,
		element: <HOUSEHOLD_PLANS.SET_FITNESS_TEMPLATE_ADMIN />
	},
	{
		path: adminDashboardPagesMenu.household.subMenu.parentList.path,
		element: <USERS_LIST_LAYOUTS.ADMIN_PARENT_LIST />
	},
	{
		path: adminDashboardPagesMenu.household.subMenu.childList.path,
		element: <USERS_LIST_LAYOUTS.ADMIN_CHILD_LIST />
	},
	{
		path: adminDashboardPagesMenu.myInfo.path,
		element: <MY_INFO.ADMIN_INFO />
	},
	{
		path: adminDashboardPagesMenu.reports.path,
		element: <USERS_LIST_LAYOUTS.ADMIN_REPORTS />
	},
	{
		path: detailsPagesMenu.adminReports.subscriptionRegReport.path,
		element: <DETAIL_PAGES.ADMIN_SUBSCRIPTION_REG_REPORT />
	},
	{
		path: detailsPagesMenu.adminReports.userRegistrationReport.path,
		element: <DETAIL_PAGES.ADMIN_USER_REG_REPORT />
	},
	{
		path: detailsPagesMenu.adminReports.choreReport.path,
		element: <DETAIL_PAGES.ADMIN_CHORE_REPORT />
	},
	{
		path: detailsPagesMenu.adminReports.fitnessReport.path,
		element: <DETAIL_PAGES.ADMIN_FITNESS_REPORT />
	},

	// ----------------------------- PARENT MENU -------------------------------
	{
		path: dashboardPagesMenu.rewardGenie.subMenu.wishlist.path,
		element: <EXTRAS.WISHLIST_PARENT />
	},
	{
		path: extraMenu.upForGrabs.path,
		element: <EXTRAS.UPFOR_GRABS_PARENT />
	},
	{
		path: setupMenu.child.path,
		element: <SETUP_PROFILE_LAYOUTS.CHILD_SETUP />
	},
	{
		path: dashboardPagesMenu.householdBudget.path,
		element: <SETUP_PROFILE_LAYOUTS.BUDGET_SETUP />
	},
	{
		path: usersListMenu.childUsers.path,
		element: <USERS_LIST_LAYOUTS.CHILD_LIST />
	},
	{
		path: dashboardPagesMenu.chores.subMenu.chorePlans.path,
		element: <USERS_LIST_LAYOUTS.CHORE_PLAN />
	},
	{
		path: dashboardPagesMenu.academics.subMenu.schoolInfo.path,
		element: <USERS_LIST_LAYOUTS.ACADEMICS />
	},
	{
		path: setupMenu.choresCustomSetup.path,
		element: <SETUP_PROFILE_LAYOUTS.CHORE_SETUP />
	},
	{
		path: setupMenu.choresInbuildSetup.path,
		element: <SETUP_PROFILE_LAYOUTS.CHORE_INBUILD_SETUP />
	},
	{
		path: dashboardPagesMenu.fitness.subMenu.fitnessPlans.path,
		element: <USERS_LIST_LAYOUTS.FITNESS_PLAN />
	},
	{
		path: setupMenu.fitnessCustomSetup.path,
		element: <SETUP_PROFILE_LAYOUTS.FITNESS_SETUP />
	},
	{
		path: setupMenu.fitnessInbuildSetup.path,
		element: <SETUP_PROFILE_LAYOUTS.FITNESS_INBUILD_SETUP />
	},
	{
		path: setupMenu.academicSetup.path,
		element: <SETUP_PROFILE_LAYOUTS.ACADEMIC_SETUP />
	},
	{
		path: setupMenu.childAcademicSetup.path,
		element: <SETUP_PROFILE_LAYOUTS.ACADEMIC_SETUP_CHILD />
	},
	{
		path: detailsPagesMenu.choreDetails.path,
		element: <DETAIL_PAGES.CHORE_DETAILS_CHILD />
	},
	{
		path: detailsPagesMenu.fitnessDetails.path,
		element: <DETAIL_PAGES.FITNESS_DETAILS_CHILD />
	},
	{
		path: detailsPagesMenu.choreDetailsParentView.path,
		element: <DETAIL_PAGES.CHORE_DETAILS_PARENT_VIEW />
	},
	{
		path: detailsPagesMenu.fitnessDetailsParentView.path,
		element: <DETAIL_PAGES.FITNESS_DETAILS_PARENT_VIEW />
	},
	{
		path: detailsPagesMenu.grabDetailsParentView.path,
		element: <DETAIL_PAGES.GRAB_DETAILS_PARENT />
	},
	{
		path: dashboardPagesMenu.academics.subMenu.childReportCards.path,
		element: <ACADEMICS.CHILD_REPORT_CARD_PARENT_VIEW />
	},
	{
		path: detailsPagesMenu.childReportCardDetails.path,
		element: <ACADEMICS.CHILD_REPORT_DETAILS_PARENT_VIEW />
	},
	{
		path: dashboardPagesMenu.chores.subMenu.choreAllocation.path,
		element: <HOUSEHOLD_PLANS.CHORE_ALLOCATION_PARENT />
	},
	{
		path: dashboardPagesMenu.fitness.subMenu.fitnessAllocation.path,
		element: <HOUSEHOLD_PLANS.FITNESS_ALLOCATION_PARENT />
	},
	{
		path: dashboardPagesMenu.rewardGenie.subMenu.rewardsList.path,
		element: <EXTRAS.REWARDS_LIST_PARENT />
	},
	{
		path: detailsPagesMenu.rewardDetailsParent.path,
		element: <EXTRAS.REWARDS_DETAILS_PARENT />
	},
	{
		path: detailsPagesMenu.parentInformationDetails.path,
		element: <MY_INFO.PARENT_INFO />
	},
	{
		path: dashboardPagesMenu.users.subMenu.userInfo.path,
		element: <USERS_LIST_LAYOUTS.PARENT_USER_LIST />
	},
	{
		path: dashboardPagesMenu.users.subMenu.addUser.path,
		element: <SETUP_PROFILE_LAYOUTS.USER_SETUP />
	},
	{
		path: extraMenu.notifications.path,
		element: <EXTRAS.PARENT_NOTIFICATION_LIST />
	},
	{
		path: dashboardPagesMenu.wallet.path,
		element: <DETAIL_PAGES.PARENT_WALLET_DETAILS />
	},
	{
		path: extraMenu.messaging.path,
		element: <MESSAGING_PAGE.MESSAGING />
	},

	// ----------------------------- CHILD MENU -------------------------------

	{
		path: childDashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},
	{
		path: childDashboardPagesMenu.myChores.path,
		element: <CHILD_DASHBOARD_PAGES.MYCHORELIST />
	},
	{
		path: childDashboardPagesMenu.myFitness.path,
		element: <CHILD_DASHBOARD_PAGES.MYFITNESSPLANLIST />
	},
	{
		path: childDashboardPagesMenu.myfamily.subMenu.myParents.path,
		element: <CHILD_DASHBOARD_PAGES.PARENTLIST />
	},
	{
		path: childDashboardPagesMenu.myfamily.subMenu.mySiblings.path,
		element: <MY_FAMILY.MY_SIBLING />
	},
	{
		path: extraMenu.growthTracker.path,
		element: <EXTRAS.GROWTH_TRACKER />
	},
	{
		path: detailsPagesMenu.growthDetailsParentView.path,
		element: <EXTRAS.GROWTH_DETAILS_PARENT />
	},
	{
		path: childExtraMenu.upforGrabs.path,
		element: <EXTRAS.UPFOR_GRABS_CHILD />
	},
	{
		path: detailsPagesMenu.grabDetailsChildView.path,
		element: <DETAIL_PAGES.GRAB_DETAILS_CHILD />
	},
	{
		path: childDashboardPagesMenu.myInfo.subMenu.myProfile.path,
		element: <MY_INFO.CHILD_INFO />
	},
	{
		path: childDashboardPagesMenu.myInfo.subMenu.siblingInfo.path,
		element: <MY_FAMILY.MY_SIBLING_INFO />
	},
	{
		path: childDashboardPagesMenu.myInfo.subMenu.wallet.path,
		element: <DETAIL_PAGES.CHILD_WALLET_DETAILS />
	},
	{
		path: childDashboardPagesMenu.myAcademics.subMenu.schoolInfo.path,
		// element: <ACADEMICS.CHILD_SCHOOL_INFO />
		element: <USERS_LIST_LAYOUTS.SCHOOL_LIST_CHILD />
	},
	{
		path: childDashboardPagesMenu.myAcademics.subMenu.reportCard.path,
		element: <ACADEMICS.CHILD_REPORT_CARD />
	},
	{
		path: detailsPagesMenu.childReportUploadSection.path,
		element: <ACADEMICS.CHILD_REPORT_CARD_UPLOAD />
	},
	{
		path: childDashboardPagesMenu.myRewards.subMenu.myWishList.path,
		element: <EXTRAS.WISHLIST_CHILD />
	},
	{
		path: childDashboardPagesMenu.myRewards.subMenu.myRewards.path,
		element: <EXTRAS.MYREWARDS_CHILD />
	},
	{
		path: detailsPagesMenu.rewardsRecordsChild.path,
		element: <EXTRAS.REWARDS_RECORDS_CHILD />
	},
	{
		path: detailsPagesMenu.rewardsDetailsChild.path,
		element: <EXTRAS.REWARDS_DETAILS_CHILD />
	},
	{
		path: childExtraMenu.notifications.path,
		element: <EXTRAS.CHILD_NOTIFICATION_LIST />
	},
	{
		path: childExtraMenu.messaging.path,
		element: <MESSAGING_PAGE.MESSAGING />
	},
	{
		path: detailsPagesMenu.parentDetailsView.path,
		element: <DETAIL_PAGES.PARENT_DETAILS_VIEW />
	},


];
const contents = [...presentation];


export default contents;
