export const allowOnlyNumberRegex = (value: any) => {
    if (/^[0-9]+$/g.test(value) || value == '') return true;
    else return false;
}

export const allowNumberWithFractionRegex = (value: any) => {
    // if (/^\d*\.?\d*$/g.test(value) || value === '') return true;
    if (/^\d*\.?\d{0,2}$/g.test(value) || value === '') return true;
    else return false;
}
// export const allowMobileNumberWithDashesRegex = (input:any)=>{
//     input = input.replace(/\D/g, '');
//     input = input.substring(0, 10);

//     if (input.length > 10) {
//         input = input.replace(/(\d{10})/, '$1 ');
//     }
//     if (input.length > 8) {
//         input = input.replace(/(\d{8})/, '$1 ');
//     }
//     if (input.length > 4) {
//         input = input.replace(/(\d{4})/, '$1 ');
//     }

//     return input;
// }

// new format mobile no 

export const allowMobileNumberWithDashesRegex = (input: any) => {
    let formattedValue = input.replace(/\D/g, '');
    if (formattedValue.length > 3) {
        formattedValue = formattedValue.replace(/(\d{3})(\d)/, '$1-$2');
    }
    if (formattedValue.length > 7) {
        formattedValue = formattedValue.replace(/(\d{3})-(\d{3})(\d{1,4})/, '$1-$2-$3');
    }

    formattedValue = formattedValue.substring(0, 12);

    return formattedValue;
}




// export const allowMobileNumberWithDashesRegex = (input:any) => {
//     // Remove all non-digit characters
//     input = input.replace(/\D/g, '');

//     // Allow only the first 10 digits
//     input = input.substring(0, 10);

//     // Format the input with dashes
//     if (input.length > 6) {
//         input = input.replace(/(\d{3})(\d{3})(\d{1,4})/, '$1-$2-$3');
//     } else if (input.length > 3) {
//         input = input.replace(/(\d{3})(\d{1,3})/, '$1-$2');
//     }

//     return input;
// }

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`!@#$%^&*()_\-+=\[\]{};':"\\|,.<>\/?~]).{8,}$/;

export const uppercaseRegex = /[A-Z]/;
export const lowercaseRegex = /[a-z]/;
export const numberRegex = /[0-9]/;
export const specialCharRegex = /[`!@#$%^&*()_\-+=\[\]{};':"\\|,.<>\/?~]/;
export const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/;