import React, { useEffect, useState } from 'react';
import { ERROR, FAILURE, LOADING, SUCCESS } from '../common/default/statusMessage';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import companyLogo from '../assets/img/dd-logo-mini.png';

const WindmillLoader = ( props : any) => {
    const [showMsg, setShowMsg] = useState<boolean>(false);
    const [showSecondMsg, setShowSecondMsg] = useState<boolean>(false);

    const { t } = useTranslation();

    useEffect(() => {
        setTimeout(() => {
            setShowMsg(true);
        }, 5000);

        setTimeout(() => {
            setShowSecondMsg(true)
          }, 10000);
    }, [])

    return (
        <>
            <div className='text-overlay'>
                {!showMsg ?
                    <span>
                        {/* {(t('pleaseWaitForSendEmail'))} */}
                        {(t('pleaseWaitOurRocketShipIsDeliveringYourEmailNow'))}
                    </span>
                    :
                    <div>
                        {props.mailSendingStatus === SUCCESS &&
                            <>
                                {(!showSecondMsg) ? (
                                    <p className='mt-2'><strong><em>{(t('DontWorry'))}</em></strong> - {(t('fewBitsTriedEscape'))}</p>
                                ):(
                                <>
                                    <strong style={{color: '#f5d86c'}}>{(t('WooHoo'))}</strong>
                                    <p className='mt-2'>{(t('verificationLinkSentToEmail'))}</p>
                                    {/* <p className='mt-4'>{(t('PleaseCheckMailboxToProceed'))}</p> */}
                                    <p className='mt-2' style={{fontSize: "20px", fontWeight: '30px'}}>{(t('pleaseCheckYourInboxToCompleteYourSetup'))}</p>
                                    <p className="mt-0 fst-italic" style={{fontSize: "14px", color: "#faffc3"}}>{(t('notReceiveEmail'))} <a href="javascript:void(0);" className="underline text-warning" onClick={(e)=>{e.preventDefault(); props.btnClick()}}>{(t('clickHere'))}</a></p>
                                </>
                                ) }
                            </>
                        }
                        {props.mailSendingStatus === FAILURE &&
                            <>
                                {(!showSecondMsg) ? (
                                    <p className='mt-2'>{(t('Lookingforexactchange'))}</p>
                                ):(
                                <>
                                    <p className='mt-2'>{(t('ImsorryIcantdothat'))}</p>
                                    <p className='mt-2 pe-4'>
                                    {(t('alreadyregisteredplease'))}
                                        <Link to='/auth/login'
                                        className={classNames(
                                            'fw-bold m-2 btn-signIn',
                                        )}
                                        onClick={(e)=>{e.preventDefault(); props.btnClick()}}
                                        >
                                        {(t('SignIn'))}  
                                        </Link>
                                    </p>
                                </>
                                ) }
                            </>
                        }
                        {props.mailSendingStatus === ERROR &&
                            <>
                                {(!showSecondMsg) ? (
                                    <p className='mt-2'>{(t('Lookingforexactchange'))}</p>
                                ):(
                                <>
                                    <p className='mt-2'>{(t('ImsorryIcantdothat'))}</p>
                                    <p className='mt-2 pe-4'>
                                    {(t('GenericMessage.wentWrong'))}
                                        <Link to='/auth/login'
                                        className={classNames(
                                            'fw-bold m-2 btn-signIn',
                                        )}
                                        onClick={(e)=>{e.preventDefault(); props.btnClick()}}
                                        >
                                        {(t('retry'))}  
                                        </Link>
                                    </p>
                                </>
                                ) }
                            </>
                        }
                    </div>}
            </div>
            <div className='anime-body'>
                <div className='position-absolute end-0 top-0 me-4 mt-4'>
                    <img alt='logo' style={{ width: 200 }} src={String(companyLogo)} />
                </div>
                <div className="shapes">
                    <div className="rocket-holder">
                        <img src="https://i.postimg.cc/6qGrrNbV/rocket.png" className="rocket" />
                    </div>

                    <div className="shape1 shape0"></div>
                    <div className="shape2 shape0"></div>
                    <div className="shape3 shape0"></div>
                    <div className="shape4 shape0"></div>
                    <div className="shape5 shape0"></div>
                    <img src="https://i.postimg.cc/vTpvkmxS/planet1.png" className="planet1 planets" />
                    <img src="https://i.postimg.cc/HWK3V6v4/planet2.png" className="planet2 planets" />
                    <img src="https://i.postimg.cc/7ZrVNfk5/planet4.png" className="planet4 planets" />
                    <img src="https://i.postimg.cc/Twsj5P0Y/planet5.png" className="planet5 planets" />
                    <img src="https://i.postimg.cc/jS6h3txZ/planet6.png" className="planet6 planets" />
                    <img src="https://i.postimg.cc/wB6QSZ3H/planet7.png" className="planet7 planets" />
                    <img src="https://i.postimg.cc/HjDQ3PFq/planet8.png" className="planet8 planets" />
                    <img src="https://i.postimg.cc/Jn0chXb2/planet9.png" className="planet9 planets" />
                    <div className="final">
                        <img src="https://i.postimg.cc/s2w4ymZW/human.png" className="human" />
                        <img src="https://i.postimg.cc/h4J6yzHs/planet10.png" className="planet10" />
                        <img src="https://i.postimg.cc/gcf5fcpW/planet11.png" className="planet11" />
                    </div>

                </div>

            </div>
        </>
    );
}

export default WindmillLoader;