import { ENDPOINT, axiosInstance } from '../../api';

export const dashboardApiService = async () => {
    const res = await axiosInstance.post(ENDPOINT.DASHBOARD.DASHBOARD_API);
    return res;
}
export const superAdminDashboard = async (req: any) => {
    const res = await axiosInstance.post(ENDPOINT.DASHBOARD.DASHBOARD_SUPERADMIN_API.SUPER_ADMIN_DASHBOARD, req);
    return res;
}
export const grabsChartAdminService = async (req?: any) => {
    const res = await axiosInstance.post(ENDPOINT.GRAPH_API.ADMIN.GRABS_CHART_SUPER_ADMIN, req);
    return res;
}
export const houseHoldChartAdminService = async (req?: any) => {
    const res = await axiosInstance.post(ENDPOINT.GRAPH_API.ADMIN.HOUSEHOLD_CHART_SUPER_ADMIN, req);
    return res;
}
export const academicsChartAdminService = async (req?: any) => {
    const res = await axiosInstance.post(ENDPOINT.GRAPH_API.ADMIN.ACADEMICS_CHART_SUPER_ADMIN, req);
    return res;
}
export const choresAndFitnessChartAdminService = async (req?: any) => {
    const res = await axiosInstance.post(ENDPOINT.GRAPH_API.ADMIN.CHORES_AND_FITNESS_CHART_SUPPER_ADMIN, req);
    return res;
}

export const impersonateUserService = async (req: any) => {
    const res = await axiosInstance.post(ENDPOINT.DASHBOARD.IMPERSONATE, req);
    return res;
}

export const childParentListService = async (req: any) => {
    const res = await axiosInstance.post(ENDPOINT.DASHBOARD.CHILD_PARENT_LIST, req);
    return res;
}

export const adminRegistrationReportService = async(req: any) => {
    const res = await axiosInstance.post(ENDPOINT.DASHBOARD.DASHBOARD_SUPERADMIN_API.ADMIN_REGISTRATION_REPORT, req);
    return res;
}