import React from 'react';
// import ReactDOM from 'react-dom'; // For React 17
import { createRoot } from 'react-dom/client'; // For React 18
import { BrowserRouter, BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import { AuthContextProvider } from './contexts/authContext';
import './i18n';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import { store } from './common/reduxConfig/store';

const children = (
	<AuthContextProvider>
		<ThemeContextProvider>
			{/* basename={process.env.REACT_APP_BASE_PATHNAME} */}
			<BrowserRouter>
				<React.StrictMode>
					<I18nextProvider i18n={i18next}>
						<Provider store={store}>
							<App />
						</Provider >
						<ToastContainer />
					</I18nextProvider>
				</React.StrictMode>
			</BrowserRouter>
		</ThemeContextProvider>
	</AuthContextProvider>
);

const container = document.getElementById('root');

// ReactDOM.render(children, container); // For React 17
createRoot(container as Element).render(children); // For React 18

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
