import React, { useEffect, useState, useContext, ReactNode, Fragment } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import 'flatpickr/dist/themes/material_blue.css';
import 'flatpickr/dist/flatpickr.css';
import Select from '../../../components/bootstrap/forms/Select';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Button from '../../../components/bootstrap/Button';
import Option from '../../../components/bootstrap/Option';

import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
} from '../../../components/bootstrap/Modal';
import Input from '../../../components/bootstrap/forms/Input';

import Loading from '../../../components/Loading';
import NoData from '../../../components/NoData';
import { useFormik } from 'formik';
import Spinner from '../../../components/bootstrap/Spinner';
import * as Yup from 'yup';
import { TOAST_TYPE, displaySwal, toastAlert } from '../../../common/functions/displaySwal';
import { IGrowthFormFields } from '../../../common/interface/iGrowthSetup';
import { addChildWiseHeightWeightService } from '../../../common/service/growthService';
import { UserInfoContext } from '../../../App/App';
import { UserProfileType } from '../../../common/enum/enumUserProfileTypes';
import { IChangePassword } from '../../../common/interface/iAuth';
import { changePasswordService, updatePasswordService } from '../../../common/service/authService';
import { lowercaseRegex, numberRegex, passwordRegex, specialCharRegex, uppercaseRegex } from '../../../common/default/regex';
import Icon from '../../../components/icon/Icon';
import Label from '../../../components/bootstrap/forms/Label';
import { storageObj } from '../../../common/default/defaultStorage';

const updatePasswordFields: IChangePassword = {
    new_password: '',
    confirm_password: '',
    user_type_id: 0,
    user_id: 0
}

const ChangePasswordModal = (props: any) => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const userInfoDataContext: any = useContext(UserInfoContext);

    const user_type_id = props?.user_type_id;
    const user_id = props?.user_id;
    const [updatePasswordFormFields, setUpdatePasswordFormFields] = useState<IChangePassword>({ ...updatePasswordFields });
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [otp, setOtp] = useState('');
    const [otpValidationStatus, setOtpValidationStatus] = useState<boolean>(false);
    const [httpCallStatus, setHttpCallStatus] = useState<boolean>(false);
    const [showOtp, setShowOtp] = useState<boolean>(false);
    // const [usernameUpdateStatus, setUsernameUpdateStatus] = useState<boolean>(false);
    const [passwordUpdateStatus, setPasswordUpdateStatus] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [inputDisabled, setInputDisabled] = useState<boolean>(true);
    // const [isMailSending, setIsMailSending] = useState<boolean>(false);


    const successTitle = t('great');
    const errorTitle = t('gosh')
    const successButtonText = t('GenericMessage.ok');


    useEffect(() => {
        setModalOpen(props?.passwordModalOpen);
    }, [props?.passwordModalOpen]);

    const formikObjPassword = useFormik({
        enableReinitialize: true,
        initialValues: updatePasswordFormFields,
        validateOnChange: false,
        validationSchema: Yup.object({
            new_password: Yup.string().required(t('required') || '')
                .min(8, t('8charPwd') || '')
                .matches(/[A-Z]/, t('uppercasePwd') || '')
                .matches(/[a-z]/, t('lowercasePwd') || '')
                .matches(/[0-9]/, t('numberPwd') || '')
                .matches(/[^\w]/, t('spCharPwd') || ''),
            confirm_password: Yup.string()
                .required(t('required') || '')
                .oneOf([Yup.ref('new_password'), ''], t('passwordsMustMatch') || '')
        }),
        onSubmit: (values) => {
            values.user_type_id = user_type_id;
            values.user_id = user_id ? user_id : storageObj.getUserAuthId();
            setIsProcessing(true);

            changePasswordService(values)
                .then((res: any) => {
                    if (res.success == 1) {
                        toastAlert(TOAST_TYPE.SUCCESS, res?.message);
                        setPasswordUpdateStatus(true);
                        setModalOpen(false);
                        if (props?.setPasswordModalOpen) {
                            props?.setPasswordModalOpen(false)
                        }
                    } else {
                        toastAlert(TOAST_TYPE.ERROR, res?.message);
                    }
                })
                .catch((error: any) => {
                    toastAlert(TOAST_TYPE.ERROR, error?.message);
                })
                .finally(() => {
                    setIsProcessing(false);
                })
        }
    })

    const handleCloseModal = () => {
        formikObjPassword.handleReset(undefined);
        props?.passwordModalClose(false);
    }

    return (
        <Fragment>
            <form onSubmit={(e) => {
                e.preventDefault();
                formikObjPassword.handleSubmit()
            }}>
                <Modal
                    id={'r'}
                    titleId={'a'}
                    isOpen={modalOpen}
                    setIsOpen={setModalOpen}
                    isStaticBackdrop={true}
                    isScrollable={true}
                    isCentered={true}
                    isAnimation={false}>
                    <ModalHeader setIsOpen={handleCloseModal} className='growth-modal bg-l90-warning bg-gradient mb-3'>
                        <ModalTitle id='title'>
                            <h5 className='mb-0 text-dark'>{t('updatePassword')}</h5>
                        </ModalTitle>
                    </ModalHeader>

                    <ModalBody>
                        <React.Fragment>
                            <div>
                                <div className=''>
                                    <Label className='ms-1 text-dark'>{t('newPassword') || ''} <span className='text-danger'>*</span></Label>
                                    <div className='row position-relative'>
                                        <FormGroup
                                            id='new_password'
                                            // isFloating
                                            className='d-inline-block'
                                        >
                                            <Input type={showPassword ? 'text' : 'password'}
                                                value={formikObjPassword.values.new_password}
                                                isTouched={formikObjPassword.touched.new_password}
                                                autoComplete='new-password'
                                                // invalidFeedback={
                                                // 	formikObjPassword.errors.password
                                                // }
                                                isValid={formikObjPassword.isValid}
                                                onChange={formikObjPassword.handleChange}
                                            // onBlur={formikObjPassword.handleBlur}
                                            // onFocus={() => {
                                            // 	formikObjPassword.setErrors({});
                                            // }}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Button
                                                className='position-absolute'
                                                style={{
                                                    top: '2px',
                                                    right: '12px',
                                                }}
                                                onClick={() => setShowPassword(!showPassword)}
                                                icon={!showPassword ? 'VisibilityOff' : 'Visibility'}>
                                            </Button>
                                        </FormGroup>
                                    </div>
                                </div>

                                <div className='mt-2'>
                                    <p>{t('pwdCriteria')}</p>
                                    <ul>
                                        <li>
                                            <small className='mb-0'>
                                                {t('8charPwd')}
                                                {formikObjPassword.values.new_password.length >= 8 &&
                                                    <Icon
                                                        className='ms-2 fs-4'
                                                        icon={'Check'}
                                                        color={'success'}
                                                        size={'lg'}
                                                        forceFamily={'material'}
                                                    />
                                                }
                                            </small>
                                        </li>
                                        <li>
                                            <small className='mb-0'>
                                                {t('uppercasePwd')}
                                                {uppercaseRegex.test(formikObjPassword.values.new_password) &&
                                                    <Icon
                                                        className='ms-2 fs-4'
                                                        icon={'Check'}
                                                        color={'success'}
                                                        size={'lg'}
                                                        forceFamily={'material'}
                                                    />
                                                }
                                            </small>
                                        </li>
                                        <li>
                                            <small className='mb-0'>
                                                {t('lowercasePwd')}
                                                {lowercaseRegex.test(formikObjPassword.values.new_password) &&
                                                    <Icon
                                                        className='ms-2 fs-4'
                                                        icon={'Check'}
                                                        color={'success'}
                                                        size={'lg'}
                                                        forceFamily={'material'}
                                                    />
                                                }
                                            </small>
                                        </li>
                                        <li>
                                            <small className='mb-0'>
                                                {t('numberPwd')}
                                                {numberRegex.test(formikObjPassword.values.new_password) &&
                                                    <Icon
                                                        className='ms-2 fs-4'
                                                        icon={'Check'}
                                                        color={'success'}
                                                        size={'lg'}
                                                        forceFamily={'material'}
                                                    />
                                                }
                                            </small>
                                        </li>
                                        <li>
                                            <small className='mb-0'>
                                                {t('spCharPwd')}
                                                {specialCharRegex.test(formikObjPassword.values.new_password) &&
                                                    <Icon
                                                        className='ms-2 fs-4'
                                                        icon={'Check'}
                                                        color={'success'}
                                                        size={'lg'}
                                                        forceFamily={'material'}
                                                    />
                                                }
                                            </small>
                                        </li>
                                    </ul>
                                </div>

                                <Label className='ms-1 mt-4 text-dark'>{t('confirmPassword') || ''} <span className='text-danger'>*</span></Label>
                                <div className='row'>
                                    <FormGroup
                                        id='confirm_password'
                                    // isFloating
                                    >
                                        <Input type='password'
                                            value={formikObjPassword.values.confirm_password}
                                            autoComplete='new-password'
                                            isTouched={formikObjPassword.touched.confirm_password}
                                            invalidFeedback={
                                                formikObjPassword.errors.confirm_password
                                            }
                                            isValid={formikObjPassword.isValid}
                                            onChange={formikObjPassword.handleChange}
                                            onBlur={formikObjPassword.handleBlur}
                                            onFocus={() => {
                                                formikObjPassword.setErrors({});
                                            }}
                                            onKeyDown={(e: any) => e.key == 'Enter' && formikObjPassword.handleSubmit()}
                                        />
                                    </FormGroup>
                                </div>


                            </div>
                        </React.Fragment>
                    </ModalBody>

                    <ModalFooter>
                        <div className='col-12 mt-4 d-flex justify-content-center'>
                            <Button
                                color='info'
                                className='w-50 py-3'
                                onClick={formikObjPassword.handleSubmit}
                                // isDisable={isProcessing}
                                isDisable={!passwordRegex.test(formikObjPassword.values.new_password) || isProcessing}
                            >
                                {isProcessing && (
                                    <Spinner isSmall inButton isGrow />
                                )}
                                {t('Update')}
                            </Button>
                        </div>
                    </ModalFooter>

                </Modal>
            </form>
        </Fragment>
    );
}

export default ChangePasswordModal;