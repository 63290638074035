import React, { createContext, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { ToastProvider } from 'react-toast-notifications';
import { TourProvider } from '@reactour/tour';
import ThemeContext from '../contexts/themeContext';
import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/enum/enumColors';
import { getOS } from '../helpers/helpers';
import steps, { styles } from '../steps';
import AsideRoutes from '../layout/Aside/AsideRoutes';
import { BrowserRouter, Router, useNavigate } from 'react-router-dom';
import { demoPagesMenu } from '../menu';
import { storageObj } from '../common/default/defaultStorage';
import { fetchUserBasicInformationService } from '../common/service/authService';
import { childParentListService } from '../common/service/dashboardService';
import { UserProfileType } from '../common/enum/enumUserProfileTypes';

export const UserInfoContext: any = createContext({});

const App = () => {
	const [userInfoDataContext, setUserInfoDataContext] = useState(null);
	getOS();
	/**
	 * Dark Mode
	 */
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};
	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	// @ts-ignore
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	const navigate = useNavigate();

	useEffect(() => {
		if (!(!!storageObj.getToken())) {
			if (window.location.href.includes('/auth/login'))
				navigate(`../${demoPagesMenu.login.path}`);
			else
				navigate(`../${demoPagesMenu.home.path}`);
		}
		else {
			fetchUserBasicInformationService().then((res: any) => {
				if (res.success == 1) {
					const req = {
						user_type_id: res?.data?.user_type_id == UserProfileType.Parent ? UserProfileType.Child : UserProfileType.Parent,
						family_code: res?.data?.family_code
					}
					storageObj.setUserAuthId(res?.data?.id)
					childParentListService(req).then((result: any) => {
						setUserInfoDataContext({...res?.data, childParentList: result?.data});
						storageObj.setUserAuthId(res?.data?.id);
					})
					storageObj.setUserRegDate(res?.data?.created_at?.split(" ")[0]);
				}
			})
				.catch(err => {
					setUserInfoDataContext(null);
				})
		}
	}, [navigate])

	useEffect(() => {
		if (storageObj.getImpersonate() != null) {
			window.location.reload();
		}
		storageObj.setImpersonate(null)
	}, [storageObj.getImpersonate()])

	return (
		<ThemeProvider theme={theme}>
			<ToastProvider components={{ ToastContainer, Toast }}>
				<TourProvider
					steps={steps}
					styles={styles}
					showNavigation={false}
					showBadge={false}>
					<div
						ref={ref}
						className='app'
						style={{
							backgroundColor: fullScreenStatus ? 'var(--bs-body-bg)' : undefined,
							zIndex: fullScreenStatus ? 1 : undefined,
							overflow: fullScreenStatus ? 'scroll' : undefined,
						}}>
						<UserInfoContext.Provider value={userInfoDataContext}>
							<AsideRoutes />
							<Wrapper />
						</UserInfoContext.Provider>
					</div>
					<Portal id='portal-notification'>
						<ReactNotifications />
					</Portal>
				</TourProvider>
			</ToastProvider>
		</ThemeProvider>
	);
};

export default App;
