export enum UserProfileType {
    Household = '0',
    Admin = '1',
    Parent = '2',
    Child = '3',
    User = '4'
}
export enum UserProfiles {
    ADMIN = 'Admin',
    PARENT = 'Parent',
    CHILD = 'Cool Kid'
}